@import "../../../../../theme/mixins.scss";

.modal {
  :global {
    .ant-modal-body {
      max-height: 70vh;
      overflow: auto;

      @include responsive(phone) {
        max-height: 65vh;
        padding-bottom: 10px;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      & > div:first-child {
        padding: 0px 10px 0px 5px;
      }
    }
  }
}

.form {
  text-align: center;

  input,
  textarea {
    background-color: transparent;

    &:hover,
    &:focus {
      border-color: #3b395f;
      box-shadow: none;
    }
    &::placeholder {
      color: rgba(0, 0, 0, 0.88) !important;
    }
  }

  label {
    font-size: 16px !important;

    @include responsive(tablet) {
      font-size: 14px !important;
    }
  }

  & > h2 {
    font-size: 24px;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    overflow: hidden;

    @include responsive(tablet) {
      font-size: 18px;
      -webkit-line-clamp: 2;
    }
  }

  :global {
    .ant-form-item.ant-form-item-has-error .form-control {
      border-color: #ff4d4f;
      transition: border-color .1s linear;

      &:hover {
        border-color: rgba(255, 77, 79, 0.5);
      }
    }
    .ant-input-affix-wrapper {
      &:hover,
      &:focus {
        border-color: #3b395f;
        box-shadow: none;
      }
      &::placeholder {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    }
    .ant-btn css-dev-only-do-not-override-pr0fja ant-btn-primary ant-btn-sm {
      background-color: #bb0a21;
      border-radius: 4px;
      height: 45px !important;
      border: none;
      margin: 0 auto;
      color: white;
      font-size: 14px !important;
    }

    .ant-row {
      display: inline;

      & > .ant-form-item-label {
        text-align: left;
        padding: 0 0 4px;
      }
    }
  }
}

.btn {
  width: 100%;
  background-color: var(--red);
  border-radius: 4px;
  height: 45px !important;
  border: none;
  margin: 0 auto;
  color: white !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  transition: all 0.2s linear;

  &:disabled {
    background-color: var(--red_dark) !important;
  }

  &:hover {
    background-color: #bb0a21;
  }
}

.btnCancel {
  height: 40px !important;
}
