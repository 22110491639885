@import "../../../../../theme/mixins.scss";

.bg {
    background: var(--secondary_dark);
    margin: 0 -20px;
    border-top: 1px solid var(--gray);
}

.wrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
    max-width: 1480px;
    height: 100%;
    padding: 50px 20px;
    margin: 0 auto;
    overflow-y: auto;

    :global {
        .anticon-loading {
            color: var(--white) !important;
        }
    }
}

.comments {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: left;
    max-width: 705px;
    width: 100%;
    // max-height: 1000px;
    min-height: 100px;
    margin-top: 20px;
    height: 100%;
    padding-right: 5px;
    z-index: 20;

    &::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: var(--secondary_dark);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgb(30, 30, 82);
        border-radius: 10px;
    }

    :global {
        .ant-space-item:has(> span.anticon.anticon-plus-circle),
        .ant-space-item:has(> span.anticon.anticon-loading) {
            text-align: center;
        }
    }
}

.comment {
    max-width: 700px;
    width: 100%;
    align-items: flex-start;
    gap: 16px;

    @include responsive(phone) {
        gap: 6px !important;
    }

    & > :nth-child(2) {
        flex: 1;
    }

    & :global(.ant-space-item:last-child:has(button)) {
        align-self: center;
    }

    & .sendBtn {
        padding: 20px 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.8);

        &:not(.light) {
            border-color: #757474;
            &:not(:disabled) {
                background-color: var(--white);
                border-color: transparent;
            }
            & > * {
                color: rgba(12, 12, 12, 0.8);
            }
        }

        &.light:not(:disabled) {
            background-color: var(--secondary_main);
            border-color: transparent;
        }

        & > * {
            color: rgba(255, 255, 255, 0.8);
        }
        &:disabled > * {
            color: #979595;
        }
    }
}

.commentShow {
    cursor: pointer;
    text-align: center;
    color: var(--white);

    @include responsive(phone) {
        font-size: 13px;
    }
}

.avatar,
.innerAvatar {
    display: grid;
    justify-items: center;
    background-color: transparent;
    border: 1px solid #a6a6a6;
    color: #a6a6a6;
    font-size: larger !important;
    width: 45px;
    height: 45px;

    @include responsive(phone) {
        width: 40px;
        height: 40px;
    }
}

.innerAvatar {
    border: 1px solid rgb(166, 166, 166, 0.4);
    color: rgb(166, 166, 166, 0.4);
}

.input {
    padding: 4px !important;
    border-color: #a6a6a6 !important;
    background: transparent;
    color: #a6a6a6;
    font-size: 16px;
    resize: none;
    overflow-y: visible;
    box-shadow: none !important;

    &::placeholder {
        color: #a6a6a6;
    }
    :global {
        .ant-input {
            color: #a6a6a6;
            background: transparent;

            &::placeholder {
                color: #a6a6a6;
            }
        }
    }
}

.info,
.innerInfo {
    border: 1px solid #a6a6a6;
    border-radius: 7px;
    padding: 10px 15px;
    gap: 4px;
    width: 100%;

    @include responsive(phone) {
        padding: 10px;
    }
}

.innerInfo {
    border: 1px solid rgb(166, 166, 166, 0.4);
}

.block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name {
    margin-bottom: 0px !important;
    font-size: 16px !important;
    line-height: 14px !important;
    color: #fdc52c !important;

    & > span:first-child {
        margin: 0px 10px;
    }

    & > span:last-child {
        color: #dba233;
    }
}

.email {
    color: var(--white);
    font-size: 16px;
}

.text {
    color: #a6a6a6;
    font-size: 16px;
    padding: 0px 6px;

    @include responsive(phone) {
        padding: 0px;
    }

    & > .createdAt {
        font-size: small;
        font-weight: 500;
        color: #bbbbbb;
    }
}

.answersCount {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: var(--white);
    font-size: 16px;
    font-weight: 300;
    padding: 3px 6px;

    & > span {
        padding-top: 3px;
    }

    &::before {
        position: absolute;
        content: "";
        top: -4px;
        left: 0px;
        border: 0.1px solid rgb(166, 166, 166, 0.4);
        width: 100%;
    }
}

.answers {
    position: relative;
    padding: 20px 0px 17px 30px;
    gap: 10px;
    width: 100%;

    @include responsive(phone) {
        padding: 20px 0px 17px 0px;
    }

    &::before {
        position: absolute;
        content: "";
        top: 0px;
        left: 0px;
        border: 0.1px solid rgb(166, 166, 166, 0.4);
        width: 100%;
    }
}

.answer {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    width: 100%;

    @include responsive(phone) {
        gap: 6px;
    }

    & > :last-child {
        flex: 1;
    }
}

@media (max-width: 840px) {
    .wrapper {
        padding: 40px 10px;
    }
}

@media (max-width: 632px) {
    .input,
    .name,
    .email,
    .text,
    .answersCount {
        font-size: 13px !important;
    }
}
