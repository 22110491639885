body:has(.modal) {
    width: 100% !important;
}

.modal {
    :global {
        .ant-modal-content {
            & .ant-input-affix-wrapper:hover {
                border: 1px solid var(--gray);
            }
        }
        .ant-modal-close {
            &:hover {
                transform: scale(1.1);
                color: var(--white) !important;
                background-color: var(--gray);
            }
        }

        .ant-form-item {
            margin-bottom: 15px;

            & * {
                box-shadow: none !important;
            }

            & input {
                color: #000;
            }
        }
    }
}

.wrapper {
    margin-left: 6%;
    @media (max-width: 840px) {
        font-size: 13px;
        margin-left: auto;
        text-align: center;
    }
}

.form {
    max-width: 350px;
    width: 100%;
}

.loginTitle {
    font-size: 30px !important;
    color: var(--secondary_dark) !important;
    margin: 20px 0px 30px !important;

    @media (max-width: 640px) {
        font-size: 25px !important;
        margin: 10px 0px 20px !important;
    }
}

.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #403e61;
    margin-top: 5px;
}

.forgotPass, 
.register {
    cursor: pointer;
    font-weight: 600;
    margin: 0 10px;
    color: rgba($color: #000000, $alpha: 0.6);

    &:hover {
      color: rgba($color: #000000, $alpha: 0.9);
    }  
}
  
.logo {
position: relative;
top: 7px;
}
  