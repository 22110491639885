@import "../../../../../theme/mixins.scss";

.loaderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: white;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white);
    width: 100%;
    height: 100vh;
    z-index: 999;
    animation: animateTop 0.3s linear forwards;

    & > .loadingIcon {
        margin-top: auto;
        cursor: pointer;
        margin-bottom: 80px;
        color: var(--black);
        font-size: 3rem;
        transition: color 0.1s linear;

        &:hover {
            color: var(--red);
        }

        @media (max-width: 480px) {
            margin-bottom: 100px;
        }
    }

    @keyframes animateTop {
        from {
            transform: translateY(50%);
        }
        to {
            transform: translateY(0%);
        }
    }
}