.dropDown {
    &:not(.light) {
        :global {
            .ant-dropdown-menu {
                background: var(--secondary_main);

                & li {
                    & > span {
                        color: var(--white);
                    }
                }
            }
        }
    }
    :global {
        .ant-dropdown-menu {
            & li {
                padding: 0px !important;
                & > span {
                    padding: 5px 0px;
                    & > span {
                        padding: 5px 12px;
                    }
                }
            }
        }
    }
}
