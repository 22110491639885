@import "../../../../theme/mixins.scss";

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;

  @include responsive(tablet) {
    height: 50vh;
  }
}

.container {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  background-color: var(--white);
}

// information
.information {
  background-color: var(--white);
  color: var(--primary_main);
  padding: 0px 12px 30px;

  @include responsive(phone) {
    padding-bottom: 20px;
  }

  & > .header {
    position: relative;
    text-align: center;
    padding-top: 16px;
    margin-bottom: 30px;
    z-index: 1;

    @include responsive(tablet) {
      margin-bottom: 8px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("../../../../../public/images/trianglesBlack.png");
      background-position: -40px 62%;
      opacity: 0.03;
      z-index: -1;

      @include responsive(phone) {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: linear-gradient(0deg, #fff 25%, hsla(0, 0%, 100%, 0));

      @include responsive(phone) {
        display: none;
      }
    }

    & > .title {
      font-size: 48px;
      line-height: 62px;
      max-width: 1040px;
      margin: 0 auto;
      background: var(--gradient-bg);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include responsive(averageDesktop) {
        font-size: 40px;
      }
      @include responsive(phone) {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }

  .content {
    max-width: 668px;
    margin: 0 auto;

    & > p {
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;

      @include responsive(tablet) {
        padding: 0px 16px;
      }

      @include responsive(phone) {
        font-size: 14px;
        line-height: 22px;
      }

      & > a {
        text-decoration: underline;
        overflow-wrap: anywhere;
      }

      & > strong {
        font-weight: 600 !important;
      }
    }

    & > h1,
    & > h2,
    & > h3,
    & > h4 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
      color: var(--secondary_main);

      & > * {
        color: var(--secondary_main) !important;
      }

      @include responsive(phone) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    & > h1 + p,
    & > h2 + p,
    & > h3 + p,
    & > h4 + p {
      margin-top: 11px;

      @include responsive(phone) {
        margin-top: 6px;
      }
    }

    & > ul,
    & > ol {
      margin: 0 auto;
      padding: 0 19px;
      font-size: 18px;
      font-weight: 400;

      @include responsive(phone) {
        font-size: 14px;
        line-height: 22px;
      }

      & > li {
        margin: 12px 0 12px 16px;

        @include responsive(phone) {
          margin: 6px 0 6px 16px;
        }
      }
    }

    & > ul li {
      list-style-type: disc;
    }
  }
}
// information

// video
.videoContent {
  max-width: 808px;
  margin: 0 auto;
}

.videoBlock {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  margin-top: 15px;
}

.videoPlayer {
  max-height: calc(100vh - 200px);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.7);
  pointer-events: all;
}

.video {
  border: 0px;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// video

// services
.services {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  padding: 30px 24px 44px;
  background-color: var(--white);
  position: relative;
  overflow: hidden;

  @include responsive(desktop) {
    padding: 20px 12px;
  }

  @include responsive(phone) {
    padding: 0px 12px 30px;
    row-gap: 0px;
  }

  & > .service {
    display: flex;
    width: 33.3333333333%;

    @include responsive(custom) {
      @media (max-width: 640px) {
        width: 100%;
      }
    }
  }
}
// services
