@import "../../theme/mixins.scss";

.modal {
  :global {
    .ant-modal-content {
      border: 1px solid var(--gray);
      padding: 0;
      border-radius: 10px;
      .ant-modal-close {
        top: 25.5px;

        .ant-modal-close-x {
          font-size: 22px;
          font-weight: 700;
          color: var(--black);

          &:hover {
            transform: scale(1.1);
          }
        }

        @include responsive(phone) {
          top: 18.5px;

          .ant-modal-close-x {
            font-size: 20px;
            color: var(--black);
          }
        }
      }
    }
    .ant-modal-header {
      padding: 15px 24px;
      text-align: center;
      border-bottom: 0.7px solid var(--gray);
      margin-bottom: 20px;

      @include responsive(phone) {
        padding: 10px;
      }
    }

    .ant-modal-body {
      padding: 0px 24px;

      @include responsive(phone) {
        padding: 0px 10px;
      }
    }

    .ant-modal-footer {
      padding: 10px 24px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      & button {
        margin-inline-start: 0px !important;
      }

      @include responsive(phone) {
        padding: 10px;
      }
    }

    .ant-modal-title {
      color: black;
      font-size: 28px;
      font-weight: 800;
      background: var(--gradient-bg);
      -webkit-background-clip: text;
      background-clip: text;
      max-height: 90px;
      padding: 0px 15px;
      overflow: hidden;
      color: transparent;

      @include responsive(phone) {
        font-size: 24px;
      }
    }
  }
}

.subheader {
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
}
