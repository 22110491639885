.mainBlock {
  border-radius: 0px 0px 40px 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: var(--white);
  max-width: 1000px;
  width: 100%;
  padding: 40px 60px 100px;
  text-align: center;

  @media screen and (max-width: 550px) {
    padding: 40px 13px 10px;
    width: 96%;
  }
}

.form {
  :global {
    .ant-input {
      color: var(--black);
      background-color: transparent;
    }

    .ant-form-item {
      :global {
        .ant-input-affix-wrapper-status-success {
          &:not(:disabled):hover,
          &:focus {
            border: 1px solid var(--gray);
          }
        }

        .ant-input-affix-wrapper-focused {
          box-shadow: none !important;
          border: 1px solid var(--gray);
        }
      }
      margin-bottom: 15px;
    }
  }
}

.pageBlock {
  width: 100%;
  display: flex;
  justify-content: center;
}

.iconDelete {
  position: absolute;
  top: 0;
  right: 0;
  font-size: large;
  color: var(--red);
  cursor: pointer;
}

.photo {
  display: flex;
  justify-content: center;
}

.emailBlock {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
}

.emailBlock :global(.ant-form-item) {
  width: 49%;
  @media screen and (max-width: 550px) {
    width: 100%;
  }
}

.title {
  color: black;
  font-size: 28px;
  font-weight: 800;
  background: linear-gradient(90deg, #cf122d, #154284);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 40px;
}

.btn {
  color: white !important;
  background-color: #cf112c;
  height: 45px;
  width: 35%;
  font-size: 17px;
  font-weight: 500;
  margin-top: 10px;
  border-radius: 4px;
  margin-bottom: 50px;
  border: none;
  outline: none !important;

  @media (max-width: 767px) {
    width: 45%;
  }

  &:not([disabled]):hover {
    background-image: var(--gradient-bg);
  }

  &:disabled {
    color: white;
    filter: contrast(0.6);
    border: none;
  }
}

.btn span {
  color: white;
  font-size: 17px;
}

.btn[disabled] {
  color: white;
  background-color: #cf112c;
  border: none;
}
