@import "../../../theme/mixins.scss";

.container {
  max-width: 1480px;
  width: 95%;
  margin: 50px 0 0;
  overflow: hidden;

  @include responsive(desktop) {
    width: 90%;
  }

  @include responsive(phone) {
    width: 97%;
    margin-top: 0px;
  }
}

.content {
  min-height: 80vh;
  padding-bottom: 20px;
  background-color: var(--white);
  text-align: center;
  overflow: hidden;

  @include responsive(phone) {
    min-height: 50vh;
  }

  &.height {
    min-height: 100% !important;
  }
}

.imageBlock {
  position: relative;
  width: 100%;
  padding-top: 56.25%; // 16:9 aspect ratio
  overflow: hidden;
  background-image: url(../../../../public/images/preview.svg);
  background-size: cover;
  background-position: center;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }

  .textOverlay {
    position: absolute;
    z-index: 1;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    color: white;
    font-size: 10rem;
    line-height: 12rem;
    font-weight: bold;
    text-align: center;

    @include responsive(averageDesktop) {
      font-size: 9rem;
      line-height: 11rem;
      bottom: 0rem;
    }
    @media (max-width: 1024px) {
      font-size: 7.7rem;
      line-height: 9.7rem;
    }
    @media (max-width: 769px) {
      font-size: 5.7rem;
      line-height: 7.7rem;
    }
    @include responsive(phone) {
      font-size: 3.7rem;
      line-height: 5.7rem;
    }
  }
}

.main {
  background-image: url("../../../../public/images/logoCoaches.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  margin: 50px 0 10px;
}
