:root {
    --gradient-bg: linear-gradient(90deg, #cf122d 0, #154284);
    --gradient-text: linear-gradient(90deg, #cd122d, #154284);

    --black: #000000;
    --white: #ffffff;
    --yellow: #fdc52c;
    --gray: #727272;

    --primary_main: #222;
    --secondary_main: #154284;
    --secondary_dark: #181733;
    --dark: #1c1c3a;

    --red: #cf122d;
    --red_dark: #8d1a17;
}
