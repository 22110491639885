@import "../../../../theme/mixins.scss";

// video
.videoBlock {
  width: 100%;
  position: relative;
  padding-top: 50.25%;
  margin: 40px 0px;

  @include responsive(phone) {
    padding-top: 62.5%;
    margin: 20px 0px;
  }
}

.videoPlayer {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  overflow: hidden;
  pointer-events: all;
}

.video {
  border: 0px;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// video
