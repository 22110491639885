@import "../../theme/mixins.scss";

.trainers {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.mainContent {
  text-align: center;
  width: 100%;
  padding: 30px 24px 44px;
  background-color: var(--white);

  @include responsive(desktop) {
    padding: 20px 15px;
  }
}

.title {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-top: 10px;

  @include responsive(custom) {
    @media (max-width: 840px) {
      font-size: 28px;
    }
  }
  @include responsive(custom) {
    @media (max-width: 637px) {
      font-size: 22px;
      margin-bottom: 0px;
    }
  }
  @include responsive(custom) {
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
}

.loadBtn {
  display: flex;
  align-items: center;
  width: max-content;
  gap: 5px;
  border-radius: 20px;
  padding: 10px 34px;
  margin: 20px auto;
  text-transform: uppercase;
  background-image: linear-gradient(90deg, #000 0, #000);
  color: var(--white);
  font-size: 11px;
  font-weight: 600;
  border: none;
  box-shadow: 0 0.2rem 0.6rem 0.1rem rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  & > .loadIcon {
    font-size: 14px;
  }

  @media (min-width: 640px) {
    &:hover {
      background-image: var(--gradient-bg);
      transform: translateY(1px);
    }
  }
}
