.button {
  color: rgb(255, 255, 255);
  background-color: red;
  border: none;
  font-size: 12px;
  padding: 2px 5px;
  margin-bottom: 20px;
}

.button:hover {
  color: white;
}
