.button {
  color: white;
  background-color: var(--red) !important;
  height: 45px;
  width: 100%;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-top: 10px;
  border-radius: 4px;
  border: none !important;

  &:not([disabled]):hover {
    background-image: var(--gradient-bg);
  }

  &:disabled {
    color: white;
    filter: contrast(0.6);
    border: none;
  }
}

.button span {
  color: white;
  font-style: italic;
  font-size: 17px;
}