@import "../../../theme/mixins.scss";

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;

  @include responsive(tablet) {
    height: 50vh;
  }
}

.container {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
}

.project {
  background-color: var(--white);
  color: var(--primary_main);
  padding: 0px 15px 50px;

  @include responsive(phone) {
    padding: 0px 12px 50px;
  }
}

.header {
  position: relative;
  text-align: center;
  padding-top: 16px;
  margin-bottom: 30px;
  z-index: 1;

  @include responsive(tablet) {
    padding-top: 32px;
    margin-bottom: 6px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../../public/images/trianglesBlack.png");
    background-position: -40px 62%;
    opacity: 0.03;
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(0deg, #fff 25%, hsla(0, 0%, 100%, 0));
  }
}

.title {
  font-size: 48px;
  line-height: 52px;
  text-transform: uppercase;
  background: var(--gradient-bg);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include responsive(averageDesktop) {
    font-size: 40px;
  }
  @include responsive(phone) {
    font-size: 28px;
    line-height: 28px;
  }
}

.content {
  max-width: 670px;
  width: 100%;
  margin: 0 auto 32px;

  @include responsive(phone) {
    padding: 0px 12px;
  }

  & > h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: var(--secondary_main);

    & > * {
      color: var(--secondary_main) !important;
    }

    @include responsive(phone) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  font-size: 18px;
  font-weight: 400;
  line-height: 28px;

  @include responsive(phone) {
    font-size: 14px;
    line-height: 22px;
  }

  & > strong {
    font-weight: 600;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;

    @include responsive(phone) {
      font-size: 14px;
      line-height: 22px;
    }

    & > a {
      text-decoration: underline;
      overflow-wrap: anywhere;
    }

    & > strong {
      font-weight: 600;
    }
  }

  & > h1 + p,
  & > h2 + p,
  & > h3 + p,
  & > h4 + p {
    margin-top: 11px;

    @include responsive(phone) {
      margin-top: 6px;
    }
  }

  & > ul,
  & > ol {
    margin: 0 auto;
    padding: 0 19px;
    font-size: 18px;
    font-weight: 400;

    @include responsive(phone) {
      font-size: 14px;
      line-height: 22px;
    }

    & > li {
      margin: 12px 0 12px 16px;

      @include responsive(phone) {
        margin: 6px 0 6px 16px;
      }
    }
  }

  & > ul li {
    list-style-type: disc;
  }
}

.photoContainer {
  position: relative;
  max-width: 1110px;
  overflow: hidden;
  margin: 0 auto 24px;

  @include responsive(phone) {
    margin: 0;
  }
}

.photoBlock {
  position: relative;
  padding-bottom: 62.5%;

  &.active {
    &::before {
      left: 0;
      transition-delay: 0.5s;
    }
    &::after {
      right: 0;
      transition-delay: 0.5s;
    }
  }

  &::before {
    position: absolute;
    content: "";
    top: 16%;
    left: -5%;
    width: 5%;
    height: 45%;
    max-width: 36px;
    background-color: var(--red);
    z-index: 10;
    transition: left 0.5s ease, right 0.5s ease;

    @include responsive(phone) {
      width: 12px;
    }
  }
  &::after {
    position: absolute;
    content: "";
    top: 10%;
    right: -5%;
    width: 5%;
    height: 64%;
    max-width: 36px;
    background-color: var(--secondary_main);
    z-index: 10;
    transition: left 0.5s ease, right 0.5s ease;

    @include responsive(phone) {
      width: 12px;
    }
  }

  & > img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
}

.info {
  max-width: 670px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include responsive(phone) {
    & > * {
      padding: 0 12px;
    }
  }

  .heading {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: var(--secondary_main);

    @include responsive(phone) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  p {
    font-size: 18px;
    margin-bottom: 32px;
    font-weight: 400;
    line-height: 28px;

    @include responsive(phone) {
      font-size: 14px;
      line-height: 22px;
    }

    & > strong {
      font-weight: 600;
    }
  }

  .btnBlock {
    text-align: center;
    margin-bottom: 32px;

    @include responsive(phone) {
      margin-top: -10px;
    }
  }
  .btn {
    min-width: 148px;
    position: relative;
    display: inline-block;
    padding: 0 16px;
    line-height: 32px;
    border-radius: 3px;
    border: none;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #fff;
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    will-change: transform;
    transform: translateY(0);
    cursor: pointer;
    pointer-events: all;
    transition: color 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease;

    &:hover {
      transform: translateY(1px);
      box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.2);

      &::after {
        opacity: 0;
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 3px;
      z-index: -1;
      backface-visibility: hidden;
    }

    &::before {
      background: linear-gradient(270deg, #154284, #cd122d);
    }

    &::after {
      background: var(--red);
      transition: opacity 0.2s ease;
    }
  }
}

// video
.mobile {
  @include responsive(phone) {
    display: none;
  }
}
.videoContent {
  max-width: 1110px;
  margin: 0 auto;
}

.videoBlock {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  margin-top: 5px;
}

.videoPlayer {
  max-height: calc(100vh - 200px);
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.7);
  pointer-events: all;
}

.video {
  border: 0px;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
// video

// swiper
.swiperBlock {
  margin: 50px -15px 0px;

  @media (max-width: 640px) {
    margin: 35px 0px 0px;
  }
}
// swiper
