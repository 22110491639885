@import "../../../theme/mixins.scss";

.content {
  background-color: var(--white);
  max-width: 1480px;
  width: 95%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include responsive(phone) {
    width: 97%;
  }
}

.main {
  background-image: url("../../../../public/images/logoCoaches.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  margin: 50px 0 10px;
}

.title {
  color: var(--yellow);
  font-size: 56px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 50px;
  padding-top: 50px;

  @include responsive(desktop) {
    font-size: 35px;
    line-height: 35px;
  }
  @include responsive(phone) {
    font-size: 26px;
    padding-top: 30px;
    margin-bottom: 25px;
  }
}
