@import "../../theme/mixins.scss";

.container_cards {
  display: flex;
  flex-direction: column;
  padding: 30px 24px 44px;
  background: var(--white);
  position: relative;
  overflow: hidden;

  @include responsive(custom) {
    @media (max-width: 840px) {
      padding: 20px 15px 50px;
    }
  }
  @include responsive(custom) {
    @media (max-width: 637px) {
      gap: 5px;
      padding: 25px 5px;
    }
  }
  @include responsive(custom) {
    @media (max-width: 480px) {
      padding: 20px 5px 30px;
    }
  }

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 40%;
    width: 100%;
    background-position-y: top;
    background-size: 15px 15px;
    opacity: 0.1;
  }
}

.heading {
  background: var(--gradient-text);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  z-index: 1;

  & > span {
    font-weight: 400;
  }

  @include responsive(custom) {
    @media (max-width: 840px) {
      font-size: 28px;
    }
  }
  @include responsive(custom) {
    @media (max-width: 637px) {
      font-size: 22px;
      margin-bottom: 0px;
    }
  }
  @include responsive(custom) {
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  background-color: var(--white);

  @include responsive(custom) {
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
  @include responsive(custom) {
    @media (max-width: 637px) {
      grid-template-columns: 1fr !important;
      gap: 0px;
      width: 100%;
    }
  }
}

.card {
  position: relative;
  max-width: 344px;
  background: var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #efefef;
  z-index: 1;
  transition: box-shadow 0.25s linear;

  @media (min-width: 840px) {
    &:hover {
      box-shadow: 0px 3px 7px 3px rgba(239, 239, 239, 0.7);
      .imageBlock {
        transform: translateY(-10%);
      }
      .content {
        transform: translateY(-55%);
      }
      .desc {
        opacity: 1;
      }
    }
  }
}

.imageBlock {
  position: relative;
  width: 100%;
  padding-top: 62.5%;
  background-color: var(--secondary_main);
  overflow: hidden;
  transition: transform 0.25s ease-out;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
  }
}

.content {
  position: relative;
  background: var(--white);
  color: var(--black);
  margin-top: -20px;
  text-align: center;
  transition: all 0.25s ease-out;

  & > .date {
    display: none;
  }
}

.header {
  background: var(--white);
  color: var(--black);
  padding: 8px;
}

.title {
  max-height: 48px;
  overflow: hidden;
  font-size: 18px;
  margin-bottom: 5px;
  line-height: 23px;
  font-weight: 700;
}

.mobile {
  @media (max-width: 637px) {
    display: none;
  }
}

.desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  padding-bottom: 5px;
  color: var(--gray);
  opacity: 0;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}

.club {
  position: absolute;
  left: -8px;
  bottom: 0;
  font-size: 12px;
  color: var(--red);
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px;
  margin-bottom: 10px;

  & > .box {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-bottom: 1px;
    margin-right: 8px;
    background-color: var(--red);
  }
}

.clubMobile {
  font-size: 11px;
  color: var(--red);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-right: 2px;

  & > .box {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 4px;
    background-color: var(--red);
  }
}

.date {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: baseline;
  gap: 5px;
  color: var(--gray);
  padding: 8px;
  font-size: 12px;
  margin-bottom: 10px;

  & > .icon {
    font-size: smaller;
  }
}

@media (max-width: 1024px) {
  .card {
    max-width: 100%;
  }
}

@media (max-width: 637px) {
  .card {
    flex-direction: row;
    align-items: center;
    padding: 8px;
    border: none;
    border-bottom: 0.1rem solid rgba(239, 239, 239, 0.6);
    gap: 12px;

    & > .club {
      display: none;
    }

    & > .date {
      display: none;
    }
  }
  .imageBlock {
    padding-top: 0%;
    height: 100%;
    overflow: initial;
    width: auto;
    height: auto;
    & > img {
      position: initial;
      object-fit: cover;
      width: 150px !important;
      height: 93px !important;
    }
  }

  .content {
    margin-top: 0px;
    align-self: center;

    & > .date {
      display: flex;
    }
  }
  .header {
    margin-bottom: 0px;
    padding: 8px 4px;
  }
  .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
  }
  .desc {
    display: none;
  }
  .date {
    position: static;
    text-align: left;
    font-size: 10px;
    padding: 5px;
    margin-bottom: 0px;
  }
}

@media (max-width: 480px) {
  .imageBlock {
    & > img {
      width: 100px !important;
      height: 63px !important;
    }
  }
  .date {
    right: auto;
  }
}
