@import "../../../../theme/mixins.scss";

.main {
  position: relative; /* Ensure the parent element is positioned */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 100%;
  height: 60vh;
  object-fit: contain;

  @include responsive(phone) {
    height: 100%;
    max-height: 40.5vh;
  }
}

.content {
  width: 100%;
  z-index: 1;
  text-align: center;
  padding: 10px 15px 20px;
  margin-bottom: 5px;

  @include responsive(phone) {
    padding: 10px 15px;
  }
}

.title {
  font-size: 3rem;
  margin-bottom: 10px;
  color: var(--yellow);
  font-weight: 700;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  z-index: 1;

  @include responsive(tablet) {
    font-size: 2.5rem;
  }
  @include responsive(phone) {
    font-size: 1.8rem;
    margin-bottom: 6px;
  }
}

.subTitle {
  z-index: 1;
  max-width: 48.4rem;
  width: 100%;
  margin: 0 auto;
  padding: 0px 1.6rem;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  text-shadow: 1px 1px 2px rgb(185 185 185);

  @include responsive(tablet) {
    font-size: 15px;
  }
  @include responsive(phone) {
    font-size: 13px;
  }
}

.desc {
  z-index: 1;
  max-width: 48.4rem;
  width: 100%;
  margin: 0 auto;
  padding: 0px 1.6rem 10px;
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  text-shadow: 1px 1px 2px rgb(185 185 185);

  @include responsive(tablet) {
    margin: 0rem auto 0px;
    padding-bottom: 20px;
    font-size: 15px;
  }
  @include responsive(phone) {
    font-size: 13px;
    padding: 0px 1rem 20px;
  }
}

.gradient {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 20%;
  bottom: -1px;
  z-index: -1;
  background: linear-gradient(0deg, #080e1f -3rem, rgba(8, 14, 31, 0));
  will-change: top;
  transition: top 0.2s;
}
