@import "../../../../../theme/mixins.scss";

.modal {
  position: fixed;
  top: 0;
  left: -9999px;
  width: 0;
  height: 0;
  background: transparent;
  z-index: 100;
  visibility: hidden;
  transition: background 0.3s ease-out;

  &.open {
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    background: rgba(0, 0, 0, 0.3);
    visibility: visible;
  }

  & > .closeContainer {
    position: absolute;
    top: 23px;
    right: 745px;
    transform: translateX(75%);
    transition: transform 0.6s, opacity 0.4s;
    transition-delay: 0s;
    opacity: 0;
    z-index: 20;

    &.open {
      transform: translateX(0);
      opacity: 1;
      transition-delay: 0.6s;
    }

    @media (max-width: 840px) {
      top: 8px;
      left: 4px;
      right: auto;
    }

    & > button {
      padding: 10px 11px;
      border-radius: 100%;
      background: var(--red);
      border: none;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
      transition: background 0.3s;

      &:hover {
        background: var(--secondary_main);
      }

      @media (max-width: 840px) {
        padding: 7px 9px;
      }

      & > .closeIcon {
        font-size: 22px;
        color: var(--white);

        @media (max-width: 840px) {
          font-size: 16px;
        }
      }
    }
  }

  & > .contentContainer {
    position: relative;
    max-width: 770px;
    height: 100%;
    margin-left: auto;
    background: #fff;
    overflow: auto;
    transform: translateX(200%);
    transition: transform 0.6s ease-out;
    box-shadow: 0 0 100px 5px rgba(0, 0, 0, 0.3);
    overflow-x: hidden;

    &.open {
      transform: translateX(0);
    }

    @media (max-width: 840px) {
      margin-left: 16px;
    }

    & > .photo {
      & > img {
        width: 100%;
      }
    }

    & > .content {
      position: relative;
      padding: 20px 20px 64px 46px;
      box-shadow: -10px 0 250px 0 transparent;
      transform: translateX(50px);
      transition: transform 0.5s;
      transition-delay: 0.4s;
      z-index: 10;
      overflow-x: hidden;
      color: var(--primary_main);

      &.open {
        transform: translateX(0);
      }

      @include responsive(desktop) {
        padding-left: 24px;
        padding-bottom: 32px;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
        background-image: url(../../../../../../public/images/trianglesBlack.png);
        background-repeat: repeat-x;
        background-attachment: fixed;
        background-position: -50px -500px;
        opacity: 0.03;
      }
      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        height: 80px;
        background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0));
      }

      & > .title {
        position: relative;
        z-index: 10;
        background: var(--gradient-bg);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 auto 24px;
        max-width: 668px;
        font-size: 48px;
        line-height: 54px;

        @include responsive(desktop) {
          font-size: 32px;
          line-height: 36px;
        }

        @include responsive(phone) {
          font-size: 24px;
          line-height: 32px;
          text-align: left;
        }
      }

      & > .desc {
        max-width: 668px;
        text-align: justify;
        margin: 0 auto;

        & > h1,
        & > h2,
        & > h3,
        & > h4 {
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
          color: var(--secondary_main);

          & > * {
            color: var(--secondary_main) !important;
          }

          @include responsive(phone) {
            font-size: 20px;
            line-height: 22px;
          }
        }

        p {
          font-size: 18px;
          line-height: 28px;
          font-weight: 400;

          @include responsive(phone) {
            font-size: 16px;
            line-height: 26px;
          }

          & > a {
            text-decoration: underline;
            overflow-wrap: anywhere;
          }

          & > strong {
            font-weight: 600;
          }
        }

        & > h1 + p,
        & > h2 + p,
        & > h3 + p,
        & > h4 + p {
          margin-top: 11px;

          @include responsive(phone) {
            margin-top: 6px;
          }
        }

        & > ul,
        & > ol {
          margin: 0 auto 24px;
          padding: 0 19px;
          font-size: 18px;
          font-weight: 400;

          @include responsive(phone) {
            font-size: 16px;
          }

          & > li {
            margin: 12px 0 12px 16px;

            @include responsive(phone) {
              margin: 6px 0 6px 16px;
            }
          }
        }

        & > ul li {
          list-style-type: disc;
        }
      }
    }
  }
}

// loading
.loaderOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: white;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  width: 100%;
  height: 100vh;
  z-index: 999;
  animation: animateTop 0.3s linear forwards;

  @keyframes animateTop {
    from {
      transform: translateY(50%);
    }
    to {
      transform: translateY(0%);
    }
  }
}
// loading
