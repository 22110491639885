.select {
  :global {
    .ant-select-selector {
      border-color: var(--secondary_main) !important;
    }
  }
}

.menu {
  :global {
    .ant-select-item-option-selected {
      background-color: var(--secondary_main) !important;
      color: var(--white) !important;
    }
  }
}
