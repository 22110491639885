.slider {
  display: flex;

  .link {
    position: relative;
    width: 50%;
    height: 530px;
    transition: all 0.5s ease-in-out;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }

    @media (min-width: 1024px) {
      &:hover {
        width: 90%;

        & .title {
          transform: scale(1.5);
        }
      }
    }
  }

  .content {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 2.4rem;
    text-align: center;
    z-index: 10;

    & > .title {
      font-size: 2.3rem;
      transition: all 0.2s ease-in-out;
    }
  }

  .gradient {
    position: absolute;
    left: -1px;
    right: -1px;
    top: 20%;
    bottom: -1px;
    background: linear-gradient(0deg, #080e1f -2rem, rgba(8, 14, 31, 0));
    will-change: top;
    transition: top 0.2s;
  }
}

@media (max-width: 1024px) {
  .slider {
    flex-direction: column;

    .link {
      width: 100%;
      height: 300px;
    }

    .content {
      bottom: 1rem;

      & > .title {
        font-size: 1.5rem;
      }
    }

    .gradient {
      top: 5%;
      background: linear-gradient(0deg, #080e1f 4.5rem, rgba(8, 14, 31, 0));
    }
  }
}

@media (max-width: 640px) {
  .slider {
    flex-direction: column;

    .content {
      bottom: 1rem;

      & > .title {
        font-size: 1.37rem;
      }
    }

    .gradient {
      left: 0;
      right: 0;
    }
  }
}
