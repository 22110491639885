@import "../../../theme/mixins.scss";

.content {
  border: 1px solid #2f2f2f;
  border-radius: 40px;
  width: 100%;
  text-align: center;
  background-image: url("../../../../public/images/blueBack.png");
  background-position: center;
  background-size: 100%;
}

.main {
  background-image: url("../../../../public/images/logoCoaches.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.title {
  color: var(--yellow);
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-top: 20px;

  @include responsive(desktop) {
    font-size: 24px;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
  @include responsive(smallPhone) {
    margin: 12px 0 0;
  }
}

.logo {
  margin-top: 60px;
  align-self: center;

  @include responsive(phone) {
    margin-top: 40px;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 280px;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--white);
  border: 1px solid #484848;
  padding: 30px 40px 20px;
  text-align: start;
  transition: filter 0.2s linear;

  &:hover {
    filter: brightness(0.9);
  }
}

.img {
  width: 350px;
  height: 400px;
  border-radius: 10px 10px 0 0;
  object-fit: contain;
}

.emptyDesc {
  color: var(--white);
  font-size: 18px;
}

.cardList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1400px;
  width: 100%;
  margin: 20px auto 0px;
  padding: 0px 16px;

  @include responsive(tablet) {
    padding: 0px 5px;
    gap: 20px;
  }
}

.cardContent {
  padding: 20px;
}

.firstname {
  font-size: 20px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1);
}

.type {
  color: rgba(201, 201, 201, 1);
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.firstBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.cardTop {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 30px;
}

.cardHeader {
  display: flex;
  align-items: baseline;
}

.cardTitle {
  color: #bb0a21;
  font-size: 34px;
  font-weight: 600;
  flex: 1;
  line-height: 45px;
}

.salary {
  color: var(--black);
  font-size: 24px;
  font-weight: 400;
  margin-left: 30px;
  margin-top: 5px;
  flex: 0.3;
  text-wrap: nowrap;
}

.dateMobile {
  color: var(--black);
  font-size: 12px;
  font-weight: 400;
  display: none;
}

.date {
  color: var(--black);
  font-size: 16px;
  font-weight: 400;
  margin-top: 6px;
  text-wrap: nowrap;
}

.city {
  font-weight: 700;
  font-size: 16px;
  color: var(--dark);
  margin-top: 15px;
  text-transform: capitalize;

  &::first-letter {
    text-transform: initial;
  }
}

.desc {
  color: var(--gray);
  line-height: 22px;
  margin-top: 15px;
  margin-bottom: auto;
  line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-weight: 400;
  font-size: 14px;
}

.btn {
  align-self: flex-end;
  height: auto;
  padding: 6px 45px;
  margin-top: 20px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 700;
  background-color: var(--red);

  &:hover {
    background-color: #bb0a21 !important;
  }

  &.clicked {
    background-color: var(--white) !important;
    color: #bb0a21 !important;
    border: 1px solid #bb0a21 !important;
  }
}

.loadBtn {
  display: flex;
  align-items: center;
  width: max-content;
  gap: 5px;
  border-radius: 20px;
  padding: 10px 34px;
  margin: 20px auto;
  text-transform: uppercase;
  background-image: linear-gradient(90deg, #000 0, #000);
  color: var(--white);
  font-size: 11px;
  font-weight: 600;
  border: none;
  box-shadow: 0 0.2rem 0.6rem 0.1rem rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  & > .loadIcon {
    font-size: 14px;
  }

  @media (min-width: 640px) {
    &:hover {
      background-image: var(--gradient-bg);
      transform: translateY(1px);
    }
  }
}

.empty {
  max-width: 1400px;
  width: 95%;
  margin: 50px auto;

  @include responsive(desktop) {
    width: 90%;
  }

  @include responsive(phone) {
    width: 95%;
    margin-top: 40px;
  }
}

@media (max-width: 1200px) {
  .cardTitle {
    font-size: 25px;
    line-height: 34px;
  }

  .salary {
    font-size: 20px;
  }

  .date {
    font-size: 14px;
  }

  .city {
    font-size: 14px;
  }
}

@media (max-width: 840px) {
  .mainContainer {
    &::before {
      background-size: 100%;
    }

    & > img:first-child {
      width: 300px;
    }
  }

  .cardTop {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .cardHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .cardTitle {
    font-size: 25px;
    width: 100%;
  }

  .salary {
    font-size: 20px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .date {
    font-size: 14px;
    display: none;
  }

  .dateMobile {
    margin-top: 4px;
    display: block;
  }

  .city {
    font-size: 14px;
    margin-top: 10px;
  }
}

@media (max-width: 640px) {
  .mainContainer {
    & > img:first-child {
      width: 170px;
    }
  }

  .title {
    margin: 0px 0 0;
  }

  .loader {
    top: -50px;
  }

  .cardList {
    padding: 0px 5px;
    margin: 0 auto;
  }

  .card {
    padding: 25px 25px 20px;
  }

  .cardTitle {
    font-size: 22px;
    line-height: 30px;
  }

  .salary {
    font-size: 18px;
  }

  .city {
    font-size: 14px;
    margin-top: 6px;
  }

  .desc {
    font-size: 13px;
  }

  .btn {
    padding: 6px 38px;
    font-size: 14px;
  }
}
