.divider {
    max-width: 670px;
    border-color: rgba(53, 53, 53, 0.5) !important;
    margin: 80px 0px 48px !important;

    &::before, &::after {
        transform: translateY(0%) !important;
    }
}

@media (max-width: 637px) {
    .divider {
        margin: 80px 0px 30px !important;
    }
}