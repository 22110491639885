@import "../../../theme/mixins.scss";

// loader
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
  
    @include responsive(tablet) {
      height: 50vh;
    }
}
// loader

// banner
.banner {
    position: relative;
    display: block;
    padding: 0 0 37.5%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    &:hover {
        & .title {
            text-decoration: underline;
        }
    }

    @media (min-width: 1375px) {
        padding-bottom: 32rem;
    }
}

.image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
}

.content {
    position: absolute;
    bottom: 0;
    width: 100vw;
    color: #fff;
    text-align: center;

    &::before {
        content: "";
        position: absolute;
        top: -3rem;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, #181733 20%, rgba(24, 23, 51, 0.45) 65%, rgba(24, 23, 51, 0));
    }

    &::after {
        content: "";
        position: absolute;
        left: 6rem;
        right: 6rem;
        max-width: 124rem;
        margin: 0 auto;
        height: 10rem;
        bottom: -9rem;
        background-image: linear-gradient(90deg, #154284, #cd122d, #fdc52c);
        filter: blur(50px);
        border-radius: 50%;
        z-index: 0;
    }
}

.header {
    position: relative;
    z-index: 1;
    padding: 0 48px;
}

.title {
    font-size: 56px;
    font-weight: 700;
    text-align: center;
    max-width: 64rem;
    margin: 0 auto;
    position: relative;
    line-height: 60px;
}

.info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 16px;
    padding-bottom: 18px;
}

.club {
    position: relative;
    font-size: 12px;
    color: var(--yellow);
    font-weight: 500;
    text-transform: uppercase;

    &::before {
        content: "";
        position: absolute;
        left: -15px;
        top: 5px;
        width: 8px;
        height: 8px;
        background-color: var(--yellow);
    }
}

.date {
    position: relative;
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 12px;
    justify-content: center;

    & > span:first-child {
        font-size: 11px;
    }
}
// banner

//news
.container__news {
    padding: 0px 16px 1px;
}

.news {
    display: flex;
    flex-direction: column;
    background: var(--white);
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
    padding: 30px 24px 44px;
}

.heading {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font: {
        size: 36px;
        weight: 700;
    }
    line-height: 45px;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
}

.loadBtn {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 5px;
    border-radius: 20px;
    padding: 10px 34px;
    margin: 20px auto;
    text-transform: uppercase;
    background-image: linear-gradient(90deg, #000 0, #000);
    color: var(--white);
    font-size: 11px;
    font-weight: 600;
    border: none;
    box-shadow: 0 0.2rem 0.6rem 0.1rem rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;

    & > .loadIcon {
        font-size: 14px;
    }

    @media (min-width: 640px) {
        &:hover {
            background-image: var(--gradient-bg);
            transform: translateY(1px);
        }
    }
}
//news
@media (max-width: 1200px) {
    .heading {
        margin-bottom: 30px;
    }
}

@media (max-width: 1100px) {
    .title {
        font-size: 42px;
        line-height: 46px;
    }
}

@media (max-width: 840px) {
    .container__news {
        padding: 0px 8px 1px;
    }
    .news {
        padding: 20px 12px;
    }
    .heading {
        font-size: 24px !important;
        line-height: 35px;
        margin-bottom: 16px;
    }
}

@media (max-width: 800px) {
    .title {
        font-size: 28px;
        line-height: 30px;
    }

    .info {
        margin-top: 8px;
        padding-bottom: 12px;
    }
}

@media (max-width: 640px) {
    .heading {
        margin-bottom: 12px;
    }
}

@media (max-width: 480px) {
    .banner {
        padding: 0 0 100%;
    }

    .heading {
        font-size: 18px !important;
        line-height: 28px;
    }

    .image {
        object-fit: cover;
        height: 100%;
    }
}

@media (max-width: 400px) {
    .title {
        font-size: 20px;
        line-height: 22px;
    }
}
