@import "../../../../../theme/mixins.scss";

.swiper {
  position: initial;
  display: flex;
  align-items: center;
  overflow: visible;
  transition: all 0.2s ease;

  &.one {
    height: auto;
    margin-top: 0px;

    :global {
      @media (min-width: 990px) {
        .swiper-slide {
          height: 100%;

          &.swiper-slide-active {
            transform: scale(1);
          }
        }
      }
    }
  }

  @media (min-width: 990px) {
    margin-top: 30px;
  }

  @include responsive(desktop) {
    height: auto;
    overflow: hidden;
  }

  &.pb {
    padding-bottom: 30px;
  }

  .image {
    position: relative;
    display: block;
    padding-top: 62.5%;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    &.one {
      padding-top: 52.5% !important;

      @include responsive(phone) {
        padding-top: 62.5% !important;
      }
    }

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
    }
  }

  .back {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: blur(3px);
  }

  :global {
    .swiper-slide {
      overflow: hidden;
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0px !important;
      width: 200px !important;
      height: 18px;
      left: 50% !important;
      transform: translateX(-50%);
      overflow: auto;

      @media (min-width: 340px) {
        width: 250px !important;
      }

      @include responsive(tablet) {
        height: 19px;
      }
    }
    .swiper-pagination-bullet {
      display: inline-block;
      width: 40px;
      height: 7px;
      border-radius: 10px;
      opacity: 1;

      @include responsive(tablet) {
        width: 30px;
        height: 5px;
      }
    }
    & :is(.swiper-button-prev, .swiper-button-next) {
      top: auto;
      bottom: -8.5px;
      overflow: hidden;
      transform: scale(0.7);

      @include responsive(tablet) {
        transform: scale(0.6);
      }
    }

    & .swiper-button-prev {
      margin-left: 20px;
      &::after {
        content: "\2039";
        font-family: inherit;
        font-size: 55px;

        @include responsive(tablet) {
          font-size: 50px;
        }
      }
    }

    & .swiper-button-next {
      margin-right: 20px;
      &::after {
        content: "\203A";
        font-family: inherit;
        font-size: 55px;

        @include responsive(tablet) {
          font-size: 50px;
        }
      }
    }

    @media (min-width: 990px) {
      .swiper-wrapper {
        display: flex;
        align-items: center;
      }

      .swiper-slide {
        border: 1px solid rgb(132 132 132 / 20%);
        height: 90%;
        border-radius: 5px;
        transition: transform 0.5s ease-in-out;

        &.swiper-slide-active {
          z-index: 10;
          transform: scale(1.12);
          -webkit-box-shadow: 2px 4px 10px 4px rgba(34, 60, 80, 0.2);
          -moz-box-shadow: 2px 4px 10px 4px rgba(34, 60, 80, 0.2);
          box-shadow: 2px 4px 10px 4px rgba(34, 60, 80, 0.2);
          transition: transform 0.5s ease-in-out;
        }
      }
    }

    .swiper-pagination-bullet {
      border: 2px solid var(--red_dark);
      background: transparent;
      &-active {
        background-color: var(--red_dark);
      }

      @include responsive(tablet) {
        border: 1.2px solid var(--red_dark);
      }
    }
    & :is(.swiper-button-prev, .swiper-button-next) {
      &::after {
        color: var(--red_dark);
      }
    }
  }
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: blur(3px);
}
