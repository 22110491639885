@import "../../theme/mixins.scss";

.wrapper {
    width: 100%;
    padding: 0px;
    margin-top: 20px;
    overflow: hidden;

    @include responsive(phone) {
        margin-top: 35px;
    }
}

.emptyPage {
    border: 1px solid #2f2f2f;
    background-color: #171530;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    position: relative;
    padding: 40px 0px 100px;

    &::before {
        position: absolute;
        content: "";
        left: 0px;
        top: 100px;
        width: 25px;
        height: 155px;
        background-color: var(--red);
    }

    &::after {
        position: absolute;
        content: "";
        right: 0px;
        bottom: 100px;
        width: 25px;
        height: 155px;
        background-color: var(--secondary_main);
    }

    @include responsive(desktop) {
        gap: 40px;
        padding: 40px 0px 80px;
    }

    @include responsive(tablet) {
        gap: 30px;
        padding: 40px 0px 60px;

        &::before {
            top: 80px;
        }

        &::after {
            bottom: 80px;
        }
    }

    @include responsive(phone) {
        padding: 30px 0px;
        gap: 25px;
    }

    & > img {
        max-width: 350px;
        width: 100%;

        @include responsive(desktop) {
            max-width: 300px;
        }

        @include responsive(tablet) {
            max-width: 250px;
        }

        @include responsive(phone) {
            max-width: 180px;
        }
    }
}

.title {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-block-start: 0px;
    margin-block-end: 0px;
    font: {
        size: 50px;
        weight: 700;
    }
    text-align: center;
}

.text {
    text-align: center;
    font-size: 42px;
    font-weight: 600;
    color: #ffffff;
}

@media (max-width: 1200px) {
    .emptyPage {
        &::before,
        &::after {
            width: 20px;
            height: 135px;
        }
    }
    .text {
        font-size: 38px;
    }
}

@media (max-width: 840px) {
    .emptyPage {
        &::before,
        &::after {
            width: 15px;
        }
    }
    .title {
        font-size: 30px;
    }
    .text {
        font-size: 30px;
    }
}

@media (max-width: 630px) {
    .emptyPage {
        &::before,
        &::after {
            width: 10px;
            height: 100px;
        }
    }
    .text {
        font-size: 23px;
    }
}
