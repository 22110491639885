.newsRecommended {
  position: relative;
  max-width: 324px;
  background: var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid #efefef;
  z-index: 1;

  @media (min-width: 840px) {
    &:hover {
      box-shadow: 0px 3px 7px 3px rgba(239, 239, 239, 0.7);

      .imageBlock {
        transform: translateY(-10%);
      }
      .content {
        transform: translateY(-40%);
      }
      .desc {
        opacity: 1;
      }
    }
  }
}

.imageBlock {
  position: initial;
  width: 100%;
  height: 205.5px;
  overflow: hidden;
  transition: transform 0.25s ease-out;

  & > img {
    width: 100%;
    // height: 100% !important;
    object-fit: cover;
    object-position: top;
  }
}

.content {
  background: var(--white);
  color: var(--black);
  margin-top: -20px;
  text-align: center;
  transition: all 0.25s ease-out;

  & > .date {
    display: none;
  }
}

.header {
  background: var(--white);
  color: var(--black);
  padding: 8px;
}

.title {
  max-height: 48px;
  overflow: hidden;
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 23px;
  text-transform: uppercase;
}

.desc {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  font-weight: 300;
  line-height: 21px;
  padding-bottom: 5px;
  color: var(--gray);
  opacity: 0;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}

@media (max-width: 1024px) {
  .newsRecommended {
    max-width: 100%;
  }

  .imageBlock {
    height: 255.5px;
  }
}

@media (max-width: 840px) {
  .imageBlock {
    height: 220.5px;
  }
}

@media (max-width: 637px) {
  .newsRecommended {
    flex-direction: row;
    padding: 5px 8px;
    border: none;
    border-bottom: 0.1rem solid rgba(239, 239, 239, 0.6);
    gap: 7px;

    & > .date {
      display: none;
    }
  }
  .imageBlock {
    height: 100%;
    overflow: initial;
    width: auto;
    height: auto;
    & > img {
      object-fit: cover;
      width: 150px !important;
      height: 93px !important;
    }
  }

  .content {
    margin-top: 0px;
    align-self: center;

    & > .date {
      display: flex;
    }
  }
  .header {
    margin-bottom: 0px;
    padding: 8px 4px;
  }
  .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 18px;
    text-align: left;
  }
  .desc {
    display: none;
  }
}

@media (max-width: 480px) {
  .imageBlock {
    & > img {
      width: 100px !important;
      height: 63px !important;
    }
  }
}
