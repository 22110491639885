html,
body {
  background: var(--secondary_dark);
  color: var(--white);
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.font-barcelona * {
  font-family: "FC BARCELONA", sans-serif !important;
}

.font-default * {
  font-family:
    fcb-regular,
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif !important;
}

.font-default *:not(strong) {
  font-weight: 500 !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  background: none;
}

textarea {
  resize: none;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Heavy Italic"),
    local("FCBARCELONA-HeavyItalic"),
    url("./fonts/FCBARCELONA-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Heavy"),
    local("FCBARCELONA-Heavy"),
    url("./fonts/FCBARCELONA-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Extrabold Italic"),
    local("FCBARCELONA-ExtraboldItalic"),
    url("./fonts/FCBARCELONA-ExtraboldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Extrabold"),
    local("FCBARCELONA-Extrabold"),
    url("./fonts/FCBARCELONA-Extrabold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Bold Italic"),
    local("FCBARCELONA-BoldItalic"),
    url("./fonts/FCBARCELONA-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Bold"),
    local("FCBARCELONA-Bold"),
    url("./fonts/FCBARCELONA-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Semibold Italic"),
    local("FCBARCELONA-SemiboldItalic"),
    url("./fonts/FCBARCELONA-SemiboldItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Semibold"),
    local("FCBARCELONA-Semibold"),
    url("./fonts/FCBARCELONA-Semibold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Light Italic"),
    local("FCBARCELONA-LightItalic"),
    url("./fonts/FCBARCELONA-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Light"),
    local("FCBARCELONA-Light"),
    url("./fonts/FCBARCELONA-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Italic"),
    local("FCBARCELONA-Italic"),
    url("./fonts/FCBARCELONA-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA"),
    local("FCBARCELONA-Regular"),
    url("./fonts/FCBARCELONA-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "FC BARCELONA";
  src:
    local("FC BARCELONA Thin"),
    local("FCBARCELONA-Thin"),
    url("./fonts/FCBARCELONA-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
