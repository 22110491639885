@import "../../theme/mixins.scss";

.background {
  background-image: url("../../../public/images/moreLines.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 186px;
  display: grid;
  align-content: center;

  @include responsive(desktop) {
    height: 160px;
  }

  @include responsive(phone) {
    height: 150px;
  }
}

.logo {
  background-image: url("../../../public/images/footerLogo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 50px;

  @include responsive(desktop) {
    transform: scale(0.8);
  }

  @include responsive(phone) {
    transform: scale(0.75);
  }
}

.footer {
  background-color: var(--secondary_dark);
  padding: 0px !important;
  margin-top: 50px;
  max-width: 1480px;
  width: 100%;
  margin: 50px auto 0px;

  @include responsive(phone) {
    margin-top: 30px;
  }
}

.text {
  color: white;
  font-weight: 600;
  font-size: 20px;
  padding: 0px 20px;
}

.socialMedia {
  margin-top: 40px;
  text-align: center;
}

.socialNetworks {
  max-width: 1000px;
  margin: 30px auto 0px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;

  @include responsive(phone) {
    margin-top: 20px;
  }

  & :global {
    .anticon {
      font-size: 2rem;
    }
  }

  & > a {
    display: block;
    max-width: 160px;
    min-width: 104px;
    height: 110px;
    flex: 1 1 13%;
    border: 2px solid transparent;
    transition: all 0.2s linear;
    overflow: hidden;

    &:hover {
      border: 2px solid var(--white);
    }

    @media (max-width: 840px) {
      max-width: none;
      flex: 0 1 30%;
    }
    @media (max-width: 440px) {
      flex-basis: auto;
      width: calc(50% - 8px);
    }
  }
}

.facebook,
.twitter,
.youtube,
.instagram {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 12px 13px;
  color: white;
  cursor: pointer;
  height: 100%;
}

.facebook {
  background-color: #3b5998;
}

.twitter {
  background-color: rgba(85, 172, 238, 1);
}

.youtube {
  background-color: rgba(237, 30, 36, 1);

  & > img {
    width: 32px;
    margin-top: -1px;
  }
}

.instagram {
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d);
}

.description {
  text-align: left;
}

.name {
  font-size: 10.5px;
  line-height: 12px;
  font-weight: 500;
  margin-bottom: 1px;

  &.ru {
    margin-bottom: 4px;
  }
}

.url {
  text-align: left;
  height: 100%;
  font-weight: 500;
  font-size: 12.2px;
  overflow-wrap: anywhere;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.breadcrumbs {
  margin-top: 15px;
  width: 100%;
  height: 8px;
  display: flex;
  background: var(--gradient-bg);
  transition: height 0.2s ease;
  box-shadow: 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.25);
}

.footerCorporate {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 30px;
  color: var(--white);
  font-size: 13px;
  font-weight: 400;
  padding: 0 16px;
  margin-top: 150px;

  @include responsive(tablet) {
    padding: 0 32px;
  }
  @include responsive(phone) {
    font-size: 11px;
    margin-top: 75px;
  }

  & > &Container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include responsive(tablet) {
      flex-direction: column;
      align-items: baseline;
      text-align: left;
      gap: 20px;
    }

    & > div > span {
      color: var(--white);
      font-weight: 400;
    }
  }

  & &links {
    & > a {
      margin-right: 20px;
      position: relative;

      @include responsive(tablet) {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 6px 0px;
      }

      @media (min-width: 760px) {
        &:not(:last-child)::after {
          content: "|";
          position: absolute;
          right: -10px;
        }

        &:hover {
          color: var(--white);
          text-decoration: underline;
          text-decoration-color: var(--white);
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .text {
    font-size: 18px;
  }
}
