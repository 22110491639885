.forgotPass {
  cursor: pointer;
  margin: 0 10px;
  color: #403e61;
  font-weight: 600;
}

.logo {
  position: relative;
  top: 7px;
}

.btn {
  cursor: pointer;
  margin: 0 10px;
  color: #403e61 !important;
  height: 32px;
  font-size: 14px !important;
  font-weight: 600 !important;
  border: none;
  padding: 0;
  overflow: hidden;
}

.timer {
  text-align: center;
  margin-top: -15px;
}
