.input {
  height: 44px;
  border-radius: 6px;
  border: 1px solid var(--gray);
  width: 100%;
  text-align: center;
  padding-left: 10px;
  outline: none;
  font-size: large;
  font-weight: 500;
}

.input :global(.ant-input) {
  color: white;
  background-color: #181733;
}

.input :global(.ant-input::placeholder) {
  color: rgb(255, 254, 254);
}

@media (max-width: 600px) {
  .input {
    height: 44px;
    width: 100%;
  }
}
