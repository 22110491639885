@import "../../theme/mixins.scss";

body:has(.menu_left .link:global(.ant-dropdown-open)) {
  .menu_left .link:not(:global(.ant-dropdown-open)) {
    color: #aaa;
  }
  .menu_left .link:global(.ant-dropdown-open) {
    color: var(--white);
  }
}

.headerWrapper {
  position: relative;
  width: 100%;
  z-index: 100;
  height: 120px;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 119px;
  display: flex;
  flex-direction: column;
  padding: 0px;
  color: var(--white);
  transition: all 0.3s 0.1s ease-in-out;

  &.hide {
    top: -112px;
  }

  @include responsive(desktop) {
    height: 100px;
  }
}

.nav_top {
  padding: 0 25px;
  height: 49px;
  background-color: #0a0927;
}

.nav_middle {
  display: flex;
  align-items: center;
  height: 62px;
  padding: 0 25px;
  border-top: 0.1rem solid #29283d;
  background-color: var(--secondary_dark);
  @include responsive(desktop) {
    height: 0;
  }
}

.logo {
  line-height: normal;
  margin-right: 15px;
}

.menu {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  overflow: auto;
  overflow-y: hidden;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.menu_left {
  display: flex;
  align-items: center;
  color: #fff;
  &:hover > .link:not(:hover) {
    color: #aaa;
  }
  & > .link.active {
    color: var(--white);
  }
}

.menu_right {
  display: flex;
  align-items: center;
  color: var(--yellow) !important;

  &:hover > .link:hover {
    color: var(--yellow) !important;
  }
  & > .link.active {
    color: var(--yellow) !important;
  }
}

.link {
  padding: 0 17px;
  margin: 0 3px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent !important;
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  gap: 5px;

  @include responsive(custom) {
    @media (max-width: 1280px) {
      font-size: 12px;
    }
    @media (max-width: 1100px) {
      font-size: 10.8px;
      padding: 0 10px;
    }
  }

  :global {
    .MuiSvgIcon-root:first-child {
      font-size: large;
    }
    .ant-menu-title-content {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .anticon-caret-down {
      font-size: xx-small !important;
    }
  }
  &.active::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 2px;
    left: 0px;
    right: 0px;
    height: 0.35rem;
    background: var(--yellow);
  }
  &:focus {
    background-color: rgba(128, 127, 127, 0.1) !important;
  }
}

.dropdown {
  position: fixed;

  :global {
    .ant-dropdown-arrow {
      top: -22px;

      &::before {
        background-color: var(--secondary_main);
      }
    }
    .ant-dropdown-menu {
      top: -22px;
      background-color: var(--secondary_main);
      border-radius: 2px;
      padding: 8px 4px;

      &:has(li[role="presentation"]) {
        display: flex;

        .ant-dropdown-menu-item-group-title {
          color: var(--white);
          font-size: 17px !important;
          font-weight: 700;
          pointer-events: none;
          min-width: 200px;

          @include responsive(custom) {
            @media (max-width: 1280px) {
              font-size: 16px !important;
            }
            @media (max-width: 1100px) {
              font-size: 15px !important;
            }
          }
        }

        & > li {
          max-width: 200px;
          width: 100%;
          &:not(:last-child) {
            border-right: 1px solid rgba(255, 255, 255, 0.15);
          }
          & > ul {
            margin: 0px;
          }
        }
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 30px solid (--secondary_main);
        transform: translate(-50%, -50%);
      }
      &-item {
        color: var(--white) !important;
        font-size: 15px !important;
        font-weight: 400 !important;

        @include responsive(custom) {
          @media (max-width: 1280px) {
            font-size: 13px !important;
          }
          @media (max-width: 1100px) {
            font-size: 12px !important;
          }
        }
      }
      &:not(:has(.ant-dropdown-menu-item:only-child)) {
        .ant-dropdown-menu-item {
          padding: 7px 12px !important;
        }
      }
    }
  }
}

.innerHeader {
  padding-left: 32px;
  // padding-top: 30px;
  align-items: center;
  width: 100%;
  display: flex;
  button {
    border: none;
  }
}
.additionalBlock {
  position: absolute;
  top: 58px;
  z-index: 1212;
  height: 100vh;
  width: 100%;
}
.background {
  position: absolute;
  height: 100vh;
  background-repeat: no-repeat;
  z-index: 1;
  width: 100%;
  overflow: hidden !important;
  background-color: none;
  button {
    border: none;
  }
  &::before {
    content: "";
    background: linear-gradient(90deg, #cd122d 0, #154284) !important;
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 1;
    background-position: top; /* Set background position for top half */
  }
}
.innerItemsBack {
  padding: 6px 15px 30px 18px;
}
.blockBorder {
  padding-bottom: 13px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px hsla(0, 0%, 100%, 0.08) solid;
  width: 100%;

  & li > a {
    display: block;
    width: 100%;
    color: var(--white);
  }
}
.blockBorder2 {
  padding-bottom: 10px;
  padding-left: 20px;
  border-bottom: 1px hsla(0, 0%, 100%, 0.08) solid;
  width: 100%;
}
.closeIcon {
  font-size: 22px;
  color: var(--white);
  position: relative;
  cursor: pointer;
}

.academy {
  color: #fdc52c !important;
  font-weight: 700;
  text-align: left !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > a {
    color: #fdc52c !important;
  }

  & > span {
    font-size: smaller;
    margin-right: 20px;
  }
}
.academy2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;

  & > span {
    font-size: smaller;
    margin-right: 20px;
  }
}

.middleBlock {
  padding: 0 10px;
  // padding-left: 30px;
  // padding-top: 30px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  button {
    border: none;
  }
}
.smallMenu {
  display: flex;
  position: absolute;
  right: 12px;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
}
.badge {
  cursor: pointer;
  height: 20px;
  margin-top: -30px;
  margin-right: 5px;
  & :global(.ant-badge-count) {
    padding: 0px 4px;
  }
}
.logoSmall {
  margin-top: 10px;
}
.listItems {
  text-transform: uppercase;
  z-index: 300;
  display: flex;
  flex-direction: column;
  column-gap: 11px !important;
  align-items: flex-start;

  li {
    z-index: 300;
    width: 100%;
    margin-top: 11px;
    color: #fff;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    @media screen and (max-width: 500px) {
      font-size: 18px;
    }
  }
}

.buttons {
  margin-top: 230px;
  display: flex;
  justify-content: space-between;
}

.loginButton {
  font-size: 20px;
  z-index: 899;
  width: 200px;
  background-color: #cc092f;
  height: 40px;
  border-radius: 4px;
  border: none;
  color: white;
  margin-right: 10px;
  font-weight: 800;
  font-style: italic;
}

.registerButton {
  font-weight: 800;
  font-size: 20px;
  z-index: 899;
  background-color: #001529;
  border: none;
  color: white;
  margin-right: 10px;
  height: 40px;
}

.bottomNav {
  max-height: 54px;
  display: flex;
  background: #181733;
  justify-content: space-around;
  font-size: 16px;
  text-transform: uppercase;
  align-items: center;
  font-weight: 600;

  ::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    height: 5px;
    box-shadow: 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.25);
    background: linear-gradient(90deg, #cd122d 0, #154284);
  }

  @include responsive(smallPhone) {
    font-size: 12px;
  }

  & a {
    color: var(--white);
    transition: all 0s;

    &.active {
      color: var(--yellow);
    }
  }
}

.circle {
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.12);
  border-radius: 100%;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  height: 2.6rem;
  justify-content: center;
  transition: background-color 0.3s ease-in;
  width: 2.6rem;
  display: flex;
  flex-direction: column;
}

.icon {
  height: 1.2px;
  background: #fff;
  border-radius: 0.1rem;
  width: 1.3rem;
}
.iconMiddle {
  height: 1.2px;
  background: #fff;
  border-radius: 0.1rem;
  width: 1.3rem;
  position: relative;
  margin: 0.3rem 0;
}
.culers {
  color: white !important;
  font-weight: 600;
  background: linear-gradient(90deg, #8c1a17, #cd122d);
}
.arrow {
  width: 0;
  height: 0;
  border-top: 6px solid transparent; /* Adjust the size as needed */
  border-bottom: 6px solid transparent; /* Adjust the size as needed */
  border-right: 8px solid white; /* Adjust the color and size as needed */
}
.blockBorder3 {
  padding-bottom: 10px;
  padding-left: 20px;
  border-bottom: 1px hsla(0, 0%, 100%, 0.08) solid;
  width: 100%;
  display: flex;
  align-items: baseline;
  column-gap: 5px;
  // opacity: 0.6;
  background: hsla(0, 0%, 100%, 0.08);
  margin-top: -2px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
