.forgotPass {
  cursor: pointer;
  margin: 0 10px;
  color: rgba($color: #fff, $alpha: 0.6);

  &:hover {
    color: rgba($color: #fff, $alpha: 0.9);
  }
}

.logo {
  position: relative;
  top: 7px;
}
