@import "../../../theme/mixins.scss";

.formBlock {
  padding-top: 60px;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  padding: 60px 20px 0px;

  & > h2 {
    font-size: 24px;
    margin: 0px -20px 32px;

    @include responsive(desktop) {
      font-size: 22px;
    }
    @include responsive(phone) {
      font-size: 20px;
      margin-bottom: 25px;
    }
  }

  & > img {
    margin-bottom: 20px;
  }

  :global {
    .ant-input-clear-icon {
      color: rgba(255, 255, 255, 0.25);

      &:hover {
        color: rgba(255, 255, 255, 0.15);
      }
    }

    .ant-input-affix-wrapper:hover {
      border: 1px solid var(--gray);
    }

    .ant-form-item {
      @include responsive(phone) {
        margin-bottom: 15px;
      }
    }
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.btn {
  color: var(--white);
  background-color: var(--red);
  height: 45px;
  font-size: 17px;
  padding: 4px 25px !important;
  margin-top: 10px;
  border-radius: 4px;

  @include responsive(phone) {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.btn[disabled] {
  color: var(--white);
  background-color: var(--red_dark);
  border: none;
}

.phone :global(.react-tel-input .form-control) {
  background: var(--secondary_dark) !important;
  color: var(--white) !important;
  border: 1px solid #999999 !important;
  height: 44px !important;

  &:hover {
    border: 1px solid var(--gray) !important;
  }
}

.phone {
  &:hover {
    :global {
      .flag-dropdown {
        border: 1px solid var(--gray) !important;
      }
    }
  }
}

.phone :global(.selected-flag) {
  background: var(--secondary_dark) !important;
}

.phone :global(.flag-dropdown) {
  border: 1px solid #999999 !important;
  border-radius: 10px !important;
}

.phone :global(.country-list) {
  background: var(--secondary_dark) !important;
  color: var(--white) !important;
}

.phone :global(.country-list li) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.phone :global(.country-list li:hover) {
  background: #cf112c !important;
}

.phone :global(.react-tel-input .country-list .country.highlight) {
  background-color: #cf112c !important;
}
