@import "../../theme/mixins.scss";

.mainContainer {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  text-align: center;

  &::before {
    position: absolute;
    content: "";
    top: 90px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(https://s3.barcelona.kg/static/managementLines.svg);
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: top left;
  }

  & > * {
    position: relative;
    z-index: 1;
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--white);
  width: 100%;
  height: 100vh;
  z-index: 1000;

  & > .loadingIcon {
    position: fixed;
    left: calc(50% - 30px);
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    z-index: 999;
    color: var(--black);
    font-size: 3rem;
    border-radius: 50%;
    animation: animateCancel 1s ease-in-out;
    transition: color 0.15s ease-in-out;

    &:hover {
      color: var(--red) !important;
    }

    @include responsive(phone) {
      top: 18px;
      right: 18px;
      width: 28px;
      height: 28px;
      left: auto;
      bottom: auto;
      animation: animateCancelMobile 1.5s ease;
    }

    @keyframes animateCancel {
      from {
        bottom: -200px;
      }
      to {
        bottom: 20px;
      }
    }

    @keyframes animateCancelMobile {
      from {
        right: -200px;
      }
      to {
        right: 20px;
      }
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  height: 100%;
  border-radius: 4px;
  background-color: var(--white);
  border: 1px solid #484848;
  overflow: hidden;
  padding: 30px 40px 20px;
  text-align: start;
}

.aboutWork {
  width: 100%;
  border-radius: 4px;
  background-color: var(--white);
  margin-top: 40px;
  border: 1px solid #484848;
  padding: 30px 40px;
  text-align: start;
}

.title {
  font-size: 18px;
  color: var(--dark);
  font-weight: 700;
  margin-top: 20px;
}

.desc2 {
  font-weight: 400;
  color: var(--gray);
  font-size: 14px;
  margin-top: 10px;
}

.img {
  width: 350px;
  height: 400px;
  border-radius: 10px 10px 0 0;
  object-fit: contain;
}

.cardList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 16px;
}

.cardContent {
  padding: 20px;
}

.firstname {
  font-size: 20px;
  font-weight: 800;
  color: rgba(0, 0, 0, 1);
}

.type {
  color: rgba(201, 201, 201, 1);
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.firstBlock {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.cardTop {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 30px;
}

.cardHeader {
  display: flex;
  align-items: baseline;
}

.cardTitle {
  color: #bb0a21;
  font-size: 34px;
  font-weight: 600;
  flex: 1;
  line-height: 45px;
}

.salary {
  color: var(--black);
  font-size: 24px;
  text-wrap: nowrap;
  font-weight: 400;
  margin-left: 30px;
  flex: 0.3;
  margin-top: 5px;
}

.date {
  color: var(--black);
  font-size: 16px;
  text-wrap: nowrap;
  font-weight: 400;
  margin-top: 5px;
}

.dateMobile {
  color: var(--black);
  font-size: 12px;
  font-weight: 400;
  display: none;
}

.city {
  font-weight: 700;
  font-size: 16px;
  color: var(--dark);
  margin-top: 10px;
  text-transform: capitalize;

  &::first-letter {
    text-transform: initial;
  }
}

.experience {
  font-weight: 400;
  color: var(--black);
  font-size: 14px;
  margin: 20px 0px 10px;
}

.desc {
  font-weight: 400;
  color: var(--black);
  font-size: 14px;
  margin-bottom: auto;
}

.btn {
  align-self: flex-end;
  height: auto;
  padding: 6px 45px;
  margin-top: 20px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 700;
  background-color: var(--red);

  &:hover {
    background-color: #bb0a21 !important;
  }

  &.clicked {
    background-color: var(--white) !important;
    color: #bb0a21 !important;
    border: 1px solid #bb0a21 !important;
  }
}

.empty {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  .cardTitle {
    font-size: 25px;
    line-height: 34px;
  }

  .salary {
    font-size: 20px;
  }

  .date {
    font-size: 14px;
  }

  .city {
    font-size: 14px;
  }
}

@media (max-width: 840px) {
  .cardTop {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .cardHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .cardTitle {
    font-size: 25px;
    width: 100%;
  }

  .salary {
    font-size: 20px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .experience {
    margin: 20px 0px 5px
  }

  .date {
    font-size: 14px;
    display: none;
  }

  .dateMobile {
    margin-top: 4px;
    display: block;
  }

  .city {
    font-size: 14px;
    margin-top: 10px;
  }
}

@media (max-width: 640px) {
  .cardList {
    padding: 0px 5px;
  }

  .aboutWork {
    padding: 10px 25px 30px;
  }

  .card {
    padding: 25px 25px 20px;
  }

  .cardTitle {
    font-size: 22px;
    line-height: 30px;
  }

  .salary {
    font-size: 18px;
  }

  .city {
    font-size: 14px;
    margin-top: 6px;
  }

  .desc {
    font-size: 13px;
  }

  .btn {
    padding: 6px 38px;
    font-size: 14px;
  }
}
