.slider {
  display: flex;
  min-height: 350px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4);

  @media (min-width: 1025px) {
    height: 65vh;
  }

  & > .loading {
    display: flex;
    width: 100%;

    & > .slide {
      width: 50%;
      border: 0.8px solid var(--gray);
      background-image: url(../../../../../public/images/preview.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-color: #171530;

      &:nth-child(2) {
        border-width: 0.8px 0px;
      }

      & > .loader {
        height: 100%;
      }
    }
  }

  .link {
    position: relative;
    width: 50%;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
      transition: transform 0.5s ease-in-out;
    }

    @media (min-width: 1280px) {
      flex: 1 0 20%;
      width: 20%;
      will-change: flex;
      transition: flex 0.3s;

      &.isActive {
        flex: 1 0 60%;
        width: 60%;
      }
    }
    @media (min-width: 1025px) {
      flex: 1 0 25%;
      width: 25%;
      will-change: flex;
      transition: flex 0.3s;

      &.isActive {
        flex: 1 0 50%;
        width: 50%;

        .content {
          & > .title {
            transform: scale(2);
            transition-delay: 0.1s;
          }

          & > .desc {
            opacity: 1;
            max-height: 100px;
            transition:
              opacity 0.6s 0.1s,
              max-height 0.6s;
          }
        }

        & > .gradient {
          top: 20%;
        }
      }
    }
  }

  .content {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 24px;
    text-align: center;
    z-index: 10;

    & > .title {
      font-size: 16px;
      line-height: 1.1;
      transition: transform 0.2s ease-in-out;
      padding: 0px 20px;
      transform-origin: bottom;
      backface-visibility: hidden;
      margin: 0 auto;

      @media (min-width: 1025px) {
        width: 20vw;
      }
      @media (max-width: 400px) {
        padding: 0px 8px;
      }
    }
    & > .desc {
      max-width: 440px;
      max-height: 0;
      font-weight: 300;
      font-size: 14px;
      opacity: 0;
      margin: 0 auto;
      overflow: hidden;
      will-change: opacity, max-height, transform;
      transition:
        opacity 0.3s 0s,
        max-height 0.3s;

      @media (max-width: 1024px) {
        display: none;
      }

      &::before {
        content: "";
        display: block;
        height: 10px;
      }
    }
  }

  .gradient {
    position: absolute;
    left: -1px;
    right: -1px;
    top: 50%;
    bottom: -1px;
    background: linear-gradient(0deg, #080e1f 0rem, rgba(8, 14, 31, 0));
    will-change: top;
    transition: top 0.2s;
  }
}

@media (max-width: 1024px) {
  .slider {
    flex-direction: column;

    & > .loading {
      flex-direction: column;

      & > .slide {
        width: 100%;
        height: 300px;

        &:nth-child(2) {
          border-width: 0px 0.8px;
        }
      }
    }

    .link {
      width: 100%;
      height: 300px;
    }

    .content {
      bottom: 1rem;

      & > .title {
        font-size: 1.5rem;
      }
    }

    .gradient {
      top: -1px;
      background: linear-gradient(180deg, rgba(8, 14, 31, 0), #080e1f 100%, #080e1f);
    }
  }
}

@media (max-width: 640px) {
  .slider {
    flex-direction: column;

    & > .loading {
      & > .slide {
        background-size: cover;

        &:nth-child(2) {
          border-width: 0px 0.8px;
        }
      }
    }

    .content {
      bottom: 1rem;

      & > .title {
        font-size: 1.37rem;
      }
    }
  }
}
