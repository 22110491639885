.form {
  width: 350px;

  :global {
    .ant-input-affix-wrapper:hover {
      border: 1px solid var(--gray);
    }
    .ant-input-clear-icon {
      color: rgba(255, 255, 255, 0.25);

      &:hover {
        color: rgba(255, 255, 255, 0.15);
      }
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 370px) {
  .form {
    width: 310px;
  }
}
