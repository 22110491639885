.headerLogin {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 0px;
}

.loginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #8c1a17, #cd122d);
  border-radius: 4px;
  border: none;
  color: white !important;
  font-weight: 700;
  width: 100%;
  height: 80%;
}

.imgButton {
  width: 20px;
}

.registerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary_main);
  border-radius: 4px;
  border: none;
  color: white !important;
  font-weight: 700;
  width: 100%;
  height: 80%;
  
  &:hover > span {
    text-decoration: underline;
  }

  :global {
    .anticon-user {
      background-color: var(--secondary_main);
      height: 2.2rem;
      border-radius: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

.email {
  font-weight: 800;
  background-color: #001529;
  border-radius: 4px;
  border: none;
  color: white !important;
  width: 100%;
  height: 80%;
}

.badge {
  z-index: 99999999 !important;
  cursor: pointer;
  height: 20px;
  margin-top: -30px;
  & :global(.ant-badge-count) {
    padding: 0px 4px;
  }
}

.culers {
  padding: 0 10px;
  display: flex;
  width: 100vw;
  height: 60px;
  background: #181733;
  position: absolute;
  top: 0;
  z-index: 99999999;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
}


.closeIcon {
  width: 26px;
  height: 26px;
  position: relative;
  cursor: pointer;
  padding: 0px 15px;
}

.closeIcon:before,
.closeIcon:after {
  content: "";
  position: absolute;
  height: 23px;
  width: 2px;
  background-color: white;
  transition-duration: 0.3s;
}

.closeIcon:before {
  transform: rotate(45deg);
}

.closeIcon:after {
  transform: rotate(-45deg);
}
