.loginBlock {
  text-align: center;
}

.loginTitle {
  font-size: 30px !important;
  color: var(--yellow) !important;
  margin: 20px 0px 30px !important;
}

.logo {
  width: 56px;
}
