@import "../../../../../theme/mixins.scss";

.article {
    position: relative;
    display: flex;
    margin: 24px 0px;
    padding: 40px;
    background-image: url("../../../../../../public/images/contentPromo.png");
    background-size: contain;
    background-repeat: no-repeat;

    @include responsive(averageDesktop) {
        padding: 0px;
    }
    @include responsive(desktop) {
        background-size: 270px 100%;
    }
    @include responsive(tablet) {
        display: block;
        padding: 0px;
        background-size: 48% auto;
        overflow: hidden;
    }

    & > .imageBlock {
        flex: 1;
        position: relative;
        margin: 0 auto 24px;
        padding-bottom: 26.5%;

        @include responsive(tablet) {
            margin: 0px;
            padding-bottom: 62.5%;
        }
   
        & > img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
            transition: transform 0.3s ease-in;
            border-radius: 5px;
            object-fit: cover;

            &:hover {
                transform: scale(1.01);
            }
        }
    }

    & > .content {
        flex: 1;
        padding: 32px 50px 16px;

        @include responsive(desktop) {
            padding: 0px 16px 0px 32px;
        }
        @include responsive(tablet) {
            margin-top: 15px;
            padding: 8px 16px 0px;
        }

        & > .title {
            margin-bottom: 20px;
            font-size: 44px;
            line-height: 50px;
            color: var(--red);

            @include responsive(desktop) {
                font-size: 32px;
                line-height: 40px;
            }
            @include responsive(tablet) {
                margin-bottom: 10px;
                font-size: 18px;
                line-height: 22px;
            }
        }

        & > .desc {
            font-size: 16px;
            line-height: 28px;
            overflow: hidden;

            @include responsive(tablet) {
                height: auto;
                font-size: 12px;
                line-height: 18px;
            }
        }

        & > .btnBlock {
            color: var(--secondary_main);
            text-transform: uppercase;
            display: inline-flex;
            align-items: center;
            gap: 10px;
            margin: 32px 24px 0px 0px;
            cursor: pointer;
            transition: color 0.15s ease-in-out;

            &:hover {
                color: var(--yellow);

                & > .plusIcon {
                    transform: translateX(2px);
                }
            }

            & > span {
                font-size: 14px;
                font-style: italic;
                font-weight: 700;
            }
            & > .plusIcon {
                font-size: 20px;
                line-height: 10px;
                margin-top: -3px;
                font-style: normal;
                font-weight: 400;
                transition: transform 0.15s ease-in-out;
            }
        }
    }
}
