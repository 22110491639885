// notify
.modal {
  position: absolute;
  right: 10px;
  cursor: default;
  overflow: hidden;
  z-index: 50;
  opacity: 0;
  transform-origin: top right;
  transform: scale(0);
  transition: all 0.2s ease-in;
 
  &.show {
    transform: scale(1);
    opacity: 1;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--secondary_dark); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--secondary_main); 
  }
}

.notifications {
  position: relative;
  color: white;
  border-radius: 5px;
  width: 540px;
  background: rgba(20, 28, 58, 1);

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 20px;
    background: linear-gradient(90deg, #cf122d 0, #154284);
  }

  &::before {
    top: 0;
    left: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &::after {
    bottom: 0;
    left: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  & > .title {
    font: {
      weight: 800;
      size: 24px;
    }
    padding: 40px 10px 25px;
    border-bottom: 1px solid #5b5b5b;
    text-align: center;
  }

  & > .wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 20px 24px;
    height: 410px;
    overflow-y: auto;

    & > .notifyOne {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;

      &.read {
        opacity: 0.6;
      }

      & > .avatar {
        min-width: 50px;
        min-height: 50px;
        color: #a6a6a6;
        background: transparent;
        border: 1px solid #a6a6a6;
      }

      & > .content {
        flex: 1;
        padding: 12px;
        border: 1px solid #a6a6a6;
        border-radius: 4px;

        & > .name {
          margin-right: 12px;
          color: #cc092f;
          font-weight: 600;
        }

        & > .email1 {
          color: rgba(80, 81, 80, 1);
        }

        & > .text {
          padding: 8px 6px 0px;
          font-size: 12px;
          line-height: 16px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: var(--gray);
          overflow: hidden;
        }
      }
    }
  }
  .btns {
    padding: 23px 10px 43px;
    margin: 0px auto;
    overflow: hidden;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.36);
    display: flex;
    justify-content: center;
    gap: 8px;

    & > .btn {
      padding: 0px 60px;
      border-radius: 4px;
      font: {
        weight: 700;
        size: 20px;
      }

      &:disabled {
        color: var(--secondary_main);
        border-color: var(--secondary_main);
      }
    }
  }
}
// notify
