@import "../../../theme/mixins.scss";

.cancelIcon {
  position: fixed;
  left: calc(50% - 30px);
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  width: 60px;
  height: 60px;
  z-index: 999;
  color: var(--white);
  background-color: var(--secondary_main);
  border-radius: 50%;
  animation: animateCancel 1s ease-in-out;
  transition: background-color 0.15s ease-in-out;

  &.light {
    color: var(--white);
    background-color: var(--secondary_dark);
  }

  &:hover {
    background-color: var(--red);
    color: var(--white) !important;
  }

  @include responsive(phone) {
    top: 18px;
    right: 18px;
    width: 28px;
    height: 28px;
    left: auto;
    font-size: 0.9rem;
    bottom: auto;
    animation: animateCancelMobile 1.5s ease;
  }

  @keyframes animateCancel {
    from {
      bottom: -200px;
    }
    to {
      bottom: 20px;
    }
  }

  @keyframes animateCancelMobile {
    from {
      right: -200px;
    }
    to {
      right: 20px;
    }
  }
}

.block {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  z-index: 100;

  &::before {
    position: fixed;
    content: "";
    left: 0px;
    bottom: 34%;
    width: 5%;
    max-width: 36px;
    height: 320px;
    background-color: var(--red);
    z-index: 101;

    @include responsive(averageDesktop) {
      height: 185px;
      bottom: 50%;
      max-width: 24px;
    }

    @include responsive(desktop) {
      bottom: 30%;
    }

    @include responsive(phone) {
      width: 12px;
      height: 80px;
    }
  }

  &::after {
    position: fixed;
    content: "";
    right: 0px;
    bottom: 14%;
    width: 5%;
    max-width: 36px;
    height: 220px;
    background-color: var(--secondary_main);
    z-index: 101;

    @include responsive(averageDesktop) {
      height: 110px;
      max-width: 24px;
    }

    @include responsive(phone) {
      width: 12px;
      height: 100px;
    }
  }
}

.banner {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: var(--white);
  z-index: 100;
  pointer-events: none;
}

.imageContainer {
  position: relative;
  width: 100%;
  padding-bottom: 50%;
  z-index: 100;

  @media (min-width: 1200px) {
    padding-bottom: 35rem;
  }

  @include responsive(phone) {
    padding-bottom: 90%;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 100px;
    background-image: linear-gradient(90deg, #154284, #cd122d, #fdc52c);
    filter: blur(40px);
    border-radius: 50%;
    z-index: 10;

    @include responsive(smallPhone) {
      bottom: -30px;
    }
  }
}

.imageBlock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}

.imageBack {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  transform: scale(1.05);
}

.wrapper {
  position: relative;
  background: var(--secondary_dark);
  z-index: 102;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin-top: 45%;
  padding: 0px 20px;

  @media (min-width: 1200px) {
    margin-top: 32rem;
  }

  @include responsive(desktop) {
    margin-top: 45%;
  }
  @include responsive(phone) {
    margin-top: 80%;
    padding: 0px 15px;
  }

  .dividerLight {
    padding: 0px;
  }
  &.light {
    background: var(--white);

    .socialLinks > span,
    .socialMobile > h4 {
      color: #727272;
    }
    .content {
      .title {
        background: var(--gradient-text);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .desc {
        color: var(--primary_main);
      }
    }
  }
}

.container {
  max-width: 670px;
  width: 100%;
  margin: 0 auto;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  width: 100%;
  height: 100vh;
  z-index: 999;
  animation: animateTop 0.3s linear forwards;

  & > .loadingIcon {
    margin-top: auto;
    cursor: pointer;
    margin-bottom: 80px;
    color: var(--black);
    font-size: 3rem;
    transition: color 0.1s linear;

    &:hover {
      color: var(--red);
    }

    @media (max-width: 480px) {
      margin-bottom: 100px;
    }
  }

  @keyframes animateTop {
    from {
      transform: translateY(50%);
    }
    to {
      transform: translateY(0%);
    }
  }
}

.socialLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;

  @include responsive(phone) {
    display: none;
  }

  & > span {
    font-size: 9px;
    margin-right: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--white);
  }
  & > .link {
    padding: 8px 24px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 600;
    background-color: #55acee;
    display: flex;
    align-items: center;
    gap: 7px;
    color: var(--white);
    border: none;

    &:nth-child(2) {
      background-image: var(--gradient-text);
    }

    &:nth-child(3) {
      background-color: #3b5998;
    }

    &:nth-child(4) {
      background-color: #cf122d;
    }
  }
}

.content {
  position: relative;
  margin: 0px 0px 170px;

  .header {
    padding-top: 30px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background-image: url(../../../../public/images/trianglesBlack.png);
      background-position: -40px 62%;
      opacity: 0.03;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: linear-gradient(0deg, #fff 25%, hsla(0, 0%, 100%, 0));
    }
  }

  .title {
    color: var(--white);
    font-size: 48px;
    font-weight: 700;
    text-transform: uppercase;
    max-width: 670px;
    margin: 0 auto 10px;
  }

  .desc {
    color: var(--primary_main);
    margin: 20px 0px 30px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 300;

    @media (max-width: 637px) {
      margin: 10px 0px;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
    }

    & > h1,
    & > h2,
    & > h3,
    & > h4 {
      font-size: 26px;
      line-height: 30px;
      font-weight: 600;
      color: var(--black);

      & > * {
        color: var(--black) !important;
      }

      @media (max-width: 637px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    p {
      color: var(--primary_main);
      font-size: 22px;
      line-height: 28px;
      font-weight: 300;

      & > a {
        text-decoration: underline;
        overflow-wrap: anywhere;
      }

      & > strong {
        font-weight: 600 !important;
      }

      @media (max-width: 637px) {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
      }
    }

    & > h1 + p,
    & > h2 + p,
    & > h3 + p,
    & > h4 + p {
      margin-top: 11px;

      @media (max-width: 637px) {
        margin-top: 6px;
      }
    }

    & > ul,
    & > ol {
      margin: 0 auto;
      padding: 0 19px;
      font-size: 18px;
      font-weight: 400;

      & > li {
        margin: 12px 0 12px 16px;

        @media (max-width: 637px) {
          margin: 6px 0 6px 16px;
        }
      }
    }

    & > ul li {
      list-style-type: disc;
    }
  }
}

.container_trainers {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 50px;
  margin: 0px -20px;
  background-image: linear-gradient(to top, #154284 0, #cf122d);
  position: relative;
  overflow: hidden;

  @include responsive(phone) {
    margin: 0px -15px;
  }

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 40%;
    width: 100%;
    background: url("../../../../public/images/plus.png");
    background-position-y: top;
    background-size: 15px 15px;
    opacity: 0.1;
  }
}

.heading {
  color: var(--white);
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
  z-index: 1;

  & > span {
    font-weight: 400;
  }
}

.trainers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  background-color: var(--white);
  padding: 40px;

  @include responsive(averageDesktop) {
    padding: 34px 16px;
  }
  @include responsive(phone) {
    padding: 8px 0px;
  }
}

@media (max-width: 1024px) {
  .container_trainers {
    padding: 30px 30px 50px;
  }
  .trainers {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 840px) {
  .dividerLight {
    padding: 0px 30px !important;
  }
  .content {
    .header {
      padding-top: 30px;
    }
    &::before,
    &::after {
      width: 15px;
    }
  }

  .container_trainers {
    padding: 25px 15px 50px;
  }

  .heading {
    font-size: 28px;
    margin-bottom: 30px;
  }
}

@media (max-width: 637px) {
  .wrapper {
    max-width: 100%;

    .dividerLight {
      padding: 0px 15px !important;
    }
  }

  .content {
    margin: 0px 0px 120px;

    &::before,
    &::after {
      display: none;
    }
    .title {
      font-size: 24px;
    }
  }

  .container_trainers {
    gap: 10px;
    padding: 25px 5px;
  }

  .heading {
    font-size: 22px;
    margin-bottom: 25px;
  }

  .trainers {
    grid-template-columns: 1fr !important;
    gap: 0px;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .content {
    .header {
      padding-top: 8px;

      &::before {
        content: "";
        position: static;
        display: block;
        width: 60%;
        height: 4px;
        margin: 0 auto 16px;
        background: #efefef;
        opacity: 1;
      }
    }
  }
  .container_trainers {
    padding: 20px 5px 30px;
  }
  .heading {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
