.loginBlock {
  text-align: center;
  background-color: #181733;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.contentBlock {
  display: flex;
  height: 100%;
}

.leftDecorators {
  position: absolute;
  left: 0;
  bottom: 0;
}

.rightDecorators {
  position: absolute;
  right: 0;
  bottom: 0;
}

.formBlock {
  z-index: 111;
  margin: auto;
}

.logoBarcaIcon {
  margin-bottom: 15px;
}

@media (max-width: 700px) {
  .leftDecorators {
    display: none;
  }
  
  .rightDecorators {
    display: none;
  }
}
