@import "../../../theme/mixins.scss";

.container {
    max-width: 1480px;
    width: 100%;
    margin: 0 auto;
}

.contact {
    background-color: var(--white);
    color: var(--primary_main);
    padding: 0px 15px 32px;

    @include responsive(phone) {
        padding: 0px 12px 32px;
    }
}

.header {
    position: relative;
    text-align: center;
    padding-top: 16px;
    z-index: 1;

    @include responsive(tablet) {
        padding-top: 32px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("../../../../public/images/trianglesBlack.png");
        background-position: -40px 62%;
        opacity: 0.03;
        z-index: -1;
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: linear-gradient(0deg, #fff 25%, hsla(0, 0%, 100%, 0));
    }
}

.title {
    font-size: 48px;
    line-height: 52px;
    background: var(--gradient-bg);
    -webkit-background-clip: text;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    margin-bottom: 24px;

    @include responsive(averageDesktop) {
        font-size: 40px;
    }
    @include responsive(phone) {
        font-size: 28px;
        line-height: 28px;
    }
}

.subTitle {
    color: var(--gray);
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 40px;

    @include responsive(phone) {
        font-size: 16px;
        line-height: 18px;
        font-weight: 400;
    }
}

.content {
    max-width: 670px;
    width: 100%;
    margin: 0 auto;

    @include responsive(phone) {
        padding: 0px 12px;
    }
}

.info {
    .heading {
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        color: var(--secondary_main);

        @include responsive(phone) {
            font-size: 20px;
            line-height: 24px;
        }
    }
    p {
        font-size: 18px;
        margin-top: 11px;
        margin-bottom: 32px;
        font-weight: 400;

        @include responsive(phone) {
            font-size: 14px;
            line-height: 22px;
        }

        & > strong {
            font-weight: 600;
        }
    }
}
