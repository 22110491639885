@import "../../../../../theme/mixins.scss";

.socialLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 32px 0px 0px;

    @include responsive(phone) {
        display: none;
    }

    & > span {
        font-size: 9px;
        margin-right: 20px;
        text-transform: uppercase;
        font-weight: 600;
        color: #727272;

        &.dark {
            color: var(--white);
        }
    }

    & > .links {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        & > .facebook,
        & > .twitter,
        & > .instagram,
        & > .youtube,
        & > .link {
            position: relative;
            padding: 6px 24px;
            border-radius: 40px;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 7px;
            color: var(--white);
            border: none;
            z-index: 1;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 40px;
                z-index: -1;
            }

            &:hover::before {
                filter: brightness(85%);
                box-shadow: 0px 0px 4px 1px rgba($color: #000000, $alpha: 0.4);
            }
        }

        & > .facebook::before {
            background-color: #3b5998;
        }
        & > .twitter::before {
            background-color: #55acee;
        }
        & > .instagram::before {
            background-image: var(--gradient-text);
        }
        & > .youtube::before {
            background-color: #ed1e24;
        }

        & > .link {
            &::before {
                background-color: var(--red);
            }
        }
    }
}

// mobile

.socialMobile {
    gap: 10px;
    margin: 20px 0px;
    display: none;

    @include responsive(phone) {
        display: block;
    }

    & > h4 {
        font-size: 9px;
        margin-bottom: 22px;
        text-transform: uppercase;
        font-weight: 600;
        color: #727272;

        &.dark {
            color: var(--white);
        }
    }
    & > .links {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;

        & > .facebook,
        & > .twitter,
        & > .instagram,
        & > .youtube,
        & > .link {
            position: relative;
            padding: 10px 24px;
            border-radius: 40px;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            color: var(--white);
            border: none;
            z-index: 1;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 40px;
                z-index: -1;
            }

            &:hover::before {
                filter: brightness(85%);
                box-shadow: 0px 0px 4px 1px rgba($color: #000000, $alpha: 0.4);
            }
        }

        & > .facebook::before {
            background-color: #3b5998;
        }
        & > .twitter::before {
            background-color: #55acee;
        }
        & > .instagram::before {
            background-image: var(--gradient-text);
        }
        & > .youtube::before {
            background-color: #ed1e24;
        }

        & > .link {
            &::before {
                background-color: var(--red);
            }
        }
    }
}
