@import "../../../../theme/mixins.scss";

.container_news {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 40px 60px;
    background-color: var(--white);
    position: relative;
    overflow: hidden;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-position-y: top;
        background-repeat: no-repeat;
    }
}

.heading {
    background-image: linear-gradient(to top, #154284 0, #cf122d);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
    z-index: 1;
}

.news {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

@media (max-width: 1024px) {
    .container_news {
        padding: 30px 30px 50px;

        &::before {
            background: none;
        }
    }
    .news {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media (max-width: 840px) {
    .container_news {
        padding: 25px 15px 50px;
    }
    .heading {
        font-size: 28px !important;
    }
}

@media (max-width: 637px) {
    .container_news {
        gap: 10px;
        padding: 25px 5px;
    }
    .heading {
        font-size: 22px !important;
        margin-bottom: 0px;
    }
    .news {
        grid-template-columns: 1fr !important;
        gap: 0px;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .container_news {
        padding: 20px 5px 30px;
    }
    .heading {
        font-size: 18px !important;
    }
}
