@import "../../../theme/mixins.scss";

.container {
  max-width: 1480px;
  width: 95%;
  margin: 50px 0 0;
  overflow: hidden;

  @include responsive(desktop) {
    width: 90%;
  }

  @include responsive(phone) {
    width: 97%;
    margin-top: 0px;
  }
}

.content {
  min-height: 80vh;
  padding-bottom: 20px;
  background-color: var(--white);
  text-align: center;
  overflow: hidden;

  @include responsive(phone) {
    min-height: 50vh;
  }

  &.height {
    min-height: 100% !important;
  }
}

.main {
  background-image: url("../../../../public/images/logoCoaches.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  margin: 50px 0 10px;
}
