@import "../../../../theme/mixins.scss";

.breadcrumbs {
    width: 100%;
    height: 8px;
    display: flex;
    padding: 0 180px;
    background: var(--gradient-bg);
    transition: height 0.2s ease;
    &.show {
        height: 48px;
        font-size: 14px;
        gap: 30px;
        align-items: center;

        @include responsive(custom) {
            @media (max-width: 1100px) {
              font-size: 12px;
            }
          }
    }
    box-shadow: 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.25);

    @include responsive(tablet) {
        padding: 0px;
    }
}

.subLink {
    position: relative;
    color: #fff !important;
    cursor: pointer;
    line-height: normal;
    padding: 16px 10px;
    background-color: transparent !important;
    white-space: nowrap;
    font-weight: 400;

    &:hover::before,
    &.active::before,
    &.active:hover::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
        height: 0.25rem;
        background: #fdc52c;
    }
    &:hover::before {
        background: #fff;
    }
}