@import "../../theme/mixins.scss";

.wrapper {
  border: 1px solid #2f2f2f;
  border-radius: 0px 0px 60px 60px;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  background-size: contain;
  background-color: #FF0000; 
  background-size: 100%;
  padding: 0px 40px;
  background: transparent;

  @include responsive(phone) {
    max-width: 96%;
  }
}

.mainWrapper {
  width: 100%;
  margin-top: 5px;
}

.banner {
  width: 100%;
  height: 100%;
}

.leftDecorators {
  width: 10%;
  position: absolute;
  left: 0;
  top: 0;

  @include responsive(phone) {
    display: none;
  }
}

.rightDecorators {
  width: 10%;
  position: absolute;
  right: 0;
  top: 0;

  @include responsive(phone) {
    display: none;
  }
}
