@import "../../../../theme/mixins.scss";

.map {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 40px auto 30px;

  @include responsive(desktop) {
    width: 98%;
  }
  @include responsive(tablet) {
    margin: 40px auto 20px;
  }
  @include responsive(phone) {
    margin: 20px auto;
  }
}
