@import "../../../theme/mixins.scss";

.contentBlock {
  border: 1px solid #333155;
  width: 75%;
  margin: 0 auto;

  @include responsive(phone) {
    width: 100%;
  }
}

.wrapper {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 16px;

  @include responsive(desktop) {
    padding: 0px 8px;
  }
  @include responsive(smallPhone) {
    padding: 0px 4px;
  }
}

.breadcrumbs {
  width: 100%;
  height: 15px;
  display: flex;
  background: var(--gradient-bg);
  transition: height 0.2s ease;
  &.show {
    height: 48px;
    font-size: 14px;
    gap: 30px;
    align-items: center;
  }
  box-shadow: 0 0.3rem 0.4rem 0 rgba(0, 0, 0, 0.25);
}
