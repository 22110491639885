.additionalBlock {
    position: absolute;
    top: 58px;
    z-index: 1212;
    height: 100vh;
    width: 100%;
}

.listItems {
    z-index: 300;
    display: flex;
    flex-direction: column;
    column-gap: 11px !important;
    align-items: flex-start;
  
    li {
      z-index: 300;
      width: 100%;
      margin-top: 11px;
      color: #fff;
      text-align: left;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }
  }

  .blockBorder2 {
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom: 1px hsla(0, 0%, 100%, 0.08) solid;
    width: 100%;
  }

  .blockBorder3 {
    text-transform: uppercase;
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom: 1px hsla(0, 0%, 100%, 0.08) solid;
    width: 100%;
    display: flex;
    align-items: baseline;
    column-gap: 5px;
    background: hsla(0, 0%, 100%, 0.08);
    margin-top: -2px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .arrow {
    width: 0;
    height: 0;
    border-top: 6px solid transparent; /* Adjust the size as needed */
    border-bottom: 6px solid transparent; /* Adjust the size as needed */
    border-right: 8px solid white; /* Adjust the color and size as needed */
    margin-right: 5px;
  }

  .academy {
    color: #fdc52c !important;
    font-weight: 700;
    text-align: left !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    & > a {
      color: #fdc52c !important;
    }
  
    & > span {
      font-size: smaller;
      margin-right: 20px;
    }
  }

  .academy2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
  
    & > span {
      font-size: smaller;
      margin-right: 20px;
    }
  }
  