.newsCard {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: var(--white);
  color: var(--black);
  width: 100%;
  // border: 1px solid #efefef;
}

.branding__bar {
  width: 100%;
  height: 8px;
  background: linear-gradient(90deg, #154284 0, #cd122d);
}

.container {
  display: flex;
  width: 100%;
  padding: 16px 0px;
}

.date {
  flex: 0 1 14.5rem;

  & > .day {
    font-size: 34px;
    font-weight: 700;
    margin-right: 10px;
    line-height: 45px;
  }
  & > .month {
    font-size: 13px;
    color: var(--gray);
    text-transform: uppercase;
  }
}

.info {
  flex: 1;
  transform: translateX(-15px);
  opacity: 0.5;
  transition: all .3s .2s ease-in-out;

  &.active {
    opacity: 1;
    transform: translateX(0px);
  }
}

.link {
  display: flex;
  gap: 20px;

  @media (min-width: 640px) {
    &:hover {
      box-shadow: -10px 1px 26px -9px rgba(34, 60, 80, 0.2);

      & > .imageBlock > img {
        transform: scale(1.1) !important;
      }
      & > .content > .title {
        text-decoration: underline;
      }
    }
  }
}

.imageBlock {
  max-width: 312px;
  max-height: 180px;
  height: 180px;
  flex: 1;
  overflow: hidden;
  position: relative;

  & > img {
    // height: 100% !important;
    object-fit: cover;
    transition: all 0.7s linear;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;

  & > .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  & > .desc {
    color: var(--gray);
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 400;
  }

  & > .tag {
    display: flex;
    justify-content: space-between;

    & > .club {
      position: relative;
      font-size: 12px;
      color: var(--red);
      font-weight: 600;
      text-transform: uppercase;

      & > .box {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-bottom: 2px;
        margin-right: 4px;
        background-color: var(--red);
      }
    }

    & > .time {
      font-size: 12px;
      color: var(--gray);
      display: flex;
      align-items: baseline;
      gap: 5px;

      & > .icon {
        font-size: 8px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .container {
    display: block;
  }

  .date {
    display: flex;
    align-items: baseline;

    & > .day {
      font-size: 28px;
      line-height: 36px;
      margin-right: 4px;
    }

    & > .month {
      font-size: 12px;
    }
  }

  .info {
    padding-top: 16px;
  }
}

@media (max-width: 840px) {
  .branding__bar {
    height: 4px;
  }
  .content {
    & > .title {
      font-size: 24px;
      line-height: 28px !important;
      max-height: 56px !important;
    }
  }
  .info {
    padding-top: 5px;
  }
}

@media (max-width: 640px) {
  .link {
    flex-direction: column;
    gap: 12px;
    border: 1px solid #efefef
  }

  .date {
    & > .day {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .imageBlock {
    max-width: 100%;
    max-height: 350px;
  }
  .content {
    & > .title {
      display: block;
      font-size: 18px;
      padding: 0px 8px 0px 20px;
      text-align: center;
      max-height: 2.8rem !important;
      line-height: 22px !important;
    }
    & > .desc {
      visibility: hidden;
      height: 20px;
    }

    & > .tag {
      & > .time {
        align-self: center;
        margin-left: 5px;
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: 480px) {
  .imageBlock {
    max-height: 215px;
  }
}
