@import "../../../../../theme/mixins.scss";

// modalVideo
.modalVideo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(24, 23, 51, 0.95);
    padding: 0px 10px;
    z-index: 100;
    transform: translateY(115%);
    transition: transform 0.6s cubic-bezier(0.33, 0.05, 0.52, 0.78);
  
    @include responsive(phone) {
      padding: 0px 4px;
    }

    &.open {
      transform: none;
      transition: transform 0.6s cubic-bezier(0.11, 0.045, 0.185, 1);
  
      & .video {
        animation: videoAnim 0.4s linear 0.4s forwards;
      }
    }
  
    & .video {
      position: relative;
      max-width: 1400px;
      width: 100%;
      max-height: 80vh;
      border-radius: 50px;
      object-fit: cover;
      opacity: 0;
  
      @include responsive(tablet) {
        border-radius: 20px;
      }
      @include responsive(phone) {
        border-radius: 10px;
      }
  
      &.frame {
        max-height: 900px;
        height: 80vh;
      
        @include responsive(desktop) {
          height: 550px;
        }
        @include responsive(tablet) {
          height: 350px;
        }
        @include responsive(phone) {
          height: 250px;
        }
        @include responsive(smallPhone) {
          height: 220px;
        }
      }
      
  
      @keyframes videoAnim {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  
    & > .cancelIcon {
      position: fixed;
      left: calc(50% - 50px);
      bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      width: 60px;
      height: 60px;
      z-index: 999;
      color: var(--black);
      background-color: var(--white);
      border-radius: 50%;
      animation: animateCancel 2s ease-in-out;
      transition: all 0.15s ease-in;
  
      &:hover {
        background-color: var(--red);
        color: var(--white) !important;
      }
  
      @include responsive(desktop) {
        top: 18px;
        right: 18px;
        width: 28px;
        height: 28px;
        left: auto;
        font-size: 0.9rem;
        bottom: auto;
        animation: animateCancelMobile 1.5s ease;
      }
  
      @keyframes animateCancel {
        from {
          bottom: -200px;
        }
        to {
          bottom: 20px;
        }
      }
  
      @keyframes animateCancelMobile {
        from {
          right: -200px;
        }
        to {
          right: 20px;
        }
      }
    }
  }
  // modalVideo