.form {
  width: 350px;

  :global {
    .ant-input-affix-wrapper:hover {
      border: 1px solid var(--gray);
    }
    .ant-input-clear-icon {
      color: rgba(255, 255, 255, 0.25);

      &:hover {
        color: rgba(255, 255, 255, 0.15);
      }
    }
    .ant-form-item {
      margin-bottom: 15px;

      & .anticon-eye-invisible,
      & .anticon-eye {
        color: rgba(255, 255, 255, 1) !important;
      }
    }
  }
}

.line {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;

  & > span {
    color: var(--white);
    padding: 0px 10px;
    text-transform: uppercase;
    margin: 5px 0px;
  }

  & hr {
    flex: 1;
  }
}

.socialTitle {
  color: var(--white);
  font-size: larger;
}

.authBlock {
  width: 40px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authIcon {
  width: 90%;
}

.oauthLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 80%;
  margin: 20px auto 20px;
  position: relative;
}

.logoutBlock {
  padding: 0px !important;
}

@media (max-width: 370px) {
  .form {
    width: 310px;
  }
}
