@import "../../theme/mixins.scss";

.card {
    width: 100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border: 1px solid #efefef !important;
    border-radius: 0px !important;

    @media (min-width: 640px) {
        margin: 0 4px;
    }

    :global {
        .ant-card-cover {
            position: relative;
            padding-top: 56.25%;
            overflow: hidden;

            & > .ant-image {
                position: absolute;
                top: 0;
                display: block;
                width: 100%;

                &.ant-image-error {
                    @media (min-width: 1024px) {
                        margin-top: -30px;
                    }
                }
            }
        }
        .ant-card-body {
            padding: 8px;
            width: 100%;
            background-color: #fff;
            transition: transform 0.3s ease-in-out;
        }
        .ant-card-meta-title {
            margin: 8px 0px 4px !important;
            max-height: 50px;
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
            white-space: wrap;
            text-align: center;

            @media (min-width: 840px) {
                margin: 8px 0px !important;
            }
        }
        .ant-card-meta-description {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            height: 45px;
            -webkit-box-orient: vertical;
            text-align: center;
            transition: opacity 0.3s ease-in-out;

            @media (min-width: 840px) {
                opacity: 0;
            }
        }
    }

    @media (min-width: 840px) {
        &:hover {
            box-shadow: 0px 3px 7px 3px rgba(239, 239, 239, 0.7);

            .photo {
                transform: translateY(-5%);
            }
            :global {
                .ant-card-body {
                    transform: translateY(-50%);
                }
                .ant-card-meta-description {
                    display: -webkit-box;
                    opacity: 1;
                }
            }
        }
    }
}

.photo {
    display: block;
    width: 100%;
    object-fit: cover;
    border-radius: 0px !important;
    transition: transform 0.3s ease-in-out;
}

@media (max-width: 637px) {
    .card {
        margin-top: 12px;
        border: 1px solid #dcdada !important;

        :global {
            .ant-card-cover {
                width: 100%;
            }
            .ant-card-body {
                position: relative;
                align-self: center;
            }
            .ant-card-meta-title {
                white-space: inherit;
            }
            .ant-card-meta-description {
                opacity: 1;
                -webkit-line-clamp: 4;
            }
        }
    }
}
