@import "../../../theme/mixins.scss";

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;

  @include responsive(tablet) {
    height: 50vh;
  }
}

.container {
  max-width: 1480px;
  width: 100%;
  margin: 0 auto;
}

// video
.video {
  position: relative;
  max-width: 1464px;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;

  @include responsive(bigDesktop) {
    margin: 0 24px;
  }
  @include responsive(phone) {
    margin: 0 16px;
  }

  &::before {
    position: absolute;
    content: "";
    top: -100%;
    left: 0;
    width: 22px;
    height: 236px;
    background-color: var(--secondary_main);
    animation: arrowBeforeAnimate 0.3s linear 1s forwards;
    z-index: 10;

    @keyframes arrowBeforeAnimate {
      from {
        top: -100%;
      }
      to {
        top: 131px;
      }
    }

    @include responsive(desktop) {
      width: 16px;
      height: 160px;

      @keyframes arrowBeforeAnimate {
        from {
          top: -100%;
        }
        to {
          top: 80px;
        }
      }
    }

    @include responsive(phone) {
      width: 8px;
      height: 118px;

      @keyframes arrowBeforeAnimate {
        from {
          top: -100%;
        }
        to {
          top: 95px;
        }
      }
    }
  }

  &::after {
    position: absolute;
    content: "";
    top: 100%;
    right: 0;
    width: 22px;
    height: 144px;
    background-color: var(--red);
    animation: arrowAfterAnimate 0.3s linear 1s forwards;
    z-index: 10;

    @keyframes arrowAfterAnimate {
      from {
        top: 100%;
      }
      to {
        top: 55px;
      }
    }

    @include responsive(desktop) {
      width: 16px;
      height: 120px;

      @keyframes arrowAfterAnimate {
        from {
          top: 100%;
        }
        to {
          top: 46px;
        }
      }
    }

    @include responsive(phone) {
      width: 8px;
      height: 72px;

      @keyframes arrowAfterAnimate {
        from {
          top: 100%;
        }
        to {
          top: 32px;
        }
      }
    }
  }

  @media (min-width: 640px) {
    &:hover {
      & > .videoContent {
        & > .videoTitle {
          color: var(--yellow);
        }

        & > .playIcon {
          & > svg > path:not(:last-child) {
            fill: var(--black);
          }
        }
      }
    }
  }

  & > .videoImage {
    position: initial;

    & > img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0px;
      right: 0px;
      bottom: 0;
      background: linear-gradient(
        0deg,
        rgba(24, 23, 51, 0.9) 25%,
        rgba(24, 23, 51, 0)
      );
    }
  }

  & > .videoContent {
    position: relative;
    z-index: 10;
    max-width: 900px;
    margin: 0 auto;
    padding: 64px 24px 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;

    @include responsive(averageDesktop) {
      padding-top: 40px;
    }
    @include responsive(desktop) {
      padding: 32px 24px 12px;
    }
    @include responsive(tablet) {
      gap: 0;
    }
    @include responsive(phone) {
      padding: 46px 24px 10px;
    }

    & > .playIcon {
      font-size: 113px;
      margin-bottom: 26px;

      &.notVideo {
        opacity: 0;
      }

      @include responsive(averageDesktop) {
        font-size: 95px;
      }
      @include responsive(desktop) {
        font-size: 77px;
        margin-bottom: 16px;
      }
      @include responsive(tablet) {
        margin-top: 22px;
      }
      @include responsive(phone) {
        font-size: 63px;
        margin-bottom: 24px;
      }

      & > svg > path {
        fill: rgba($color: #000000, $alpha: 0.3);
        transition: fill 0.3s;
      }
      & > svg > path:last-child {
        fill: var(--white);
      }
    }

    & > .videoDuration {
      margin-bottom: 8px;
      padding-right: 5px;
      border-radius: 3px;
      font-size: 11px;
      background-color: var(--black);
      display: flex;
      align-items: center;
      font-weight: 500;

      @include responsive(tablet) {
        margin-bottom: 4px;
        font-size: 9px;
        padding-right: 4px;
      }

      & > .durationIcon {
        font-size: 23px;
        height: 20px;
        margin-top: 2px;
        margin: 2px -3px 0px;

        @include responsive(tablet) {
          margin: 1px -3px 0px -5px;
        }

        & > svg > path {
          fill: none;
        }
        & > svg > path:last-child {
          fill: var(--white);
          font-size: 40px;
          width: 40px;
          height: 40px;
        }
      }
    }

    & > .videoTitle {
      margin: 8px 0px 24px;
      font-size: 52px;
      line-height: 56px;
      transition: color 0.3s;

      @include responsive(averageDesktop) {
        font-size: 42px;
        line-height: 46px;
      }
      @include responsive(desktop) {
        font-size: 32px;
        line-height: 36px;
      }
      @include responsive(phone) {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 12px;
      }
    }
  }
}
// video

// information
.information {
  background-color: var(--white);
  color: var(--primary_main);
  padding: 0px 12px 10px;

  @include responsive(phone) {
    padding-top: 15px;
    padding-bottom: 20px;
  }

  & > .header {
    position: relative;
    text-align: center;
    padding-top: 32px;
    margin-bottom: 30px;
    z-index: 1;

    @include responsive(tablet) {
      padding-top: 16px;
      margin-bottom: 2px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("../../../../public/images/trianglesBlack.png");
      background-position: -40px 62%;
      opacity: 0.03;
      z-index: -1;

      @include responsive(phone) {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: linear-gradient(0deg, #fff 25%, hsla(0, 0%, 100%, 0));

      @include responsive(phone) {
        display: none;
      }
    }

    & > .title {
      font-size: 48px;
      line-height: 58px;
      max-width: 1040px;
      margin: 0 auto;
      background: var(--gradient-bg);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include responsive(averageDesktop) {
        font-size: 40px;
        line-height: 50px;
      }
      @include responsive(phone) {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }

  .content {
    max-width: 668px;
    margin: 0 auto;

    & > p {
      font-size: 18px;
      line-height: 28px;

      @include responsive(tablet) {
        padding: 0px 12px;
      }
      @include responsive(phone) {
        font-size: 14px;
        line-height: 22px;
      }

      & > a {
        text-decoration: underline;
        overflow-wrap: anywhere;
      }

      & > strong {
        font-weight: 600 !important;
      }
    }

    & > h1,
    & > h2,
    & > h3,
    & > h4 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 600;
      color: var(--secondary_main);

      & > * {
        color: var(--secondary_main) !important;
      }

      @include responsive(phone) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    & > h1 + p,
    & > h2 + p,
    & > h3 + p,
    & > h4 + p {
      margin-top: 11px;

      @include responsive(phone) {
        margin-top: 6px;
      }
    }

    & > ul,
    & > ol {
      margin: 0 auto;
      padding: 0 19px;
      font-size: 18px;
      font-weight: 400;

      @include responsive(phone) {
        font-size: 14px;
        line-height: 22px;
      }

      & > li {
        margin: 12px 0 12px 16px;

        @include responsive(phone) {
          margin: 6px 0 6px 16px;
        }
      }
    }

    & > ul li {
      list-style-type: disc;
    }
  }
}
// information

// services
.services {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 30px 24px 44px;
  background-color: var(--white);
  position: relative;
  overflow: hidden;

  @include responsive(desktop) {
    padding: 20px 12px;
  }
  @include responsive(phone) {
    padding: 0px 12px;
  }

  & > .header {
    position: relative;
    align-self: normal;
    padding-top: 20px;
    z-index: 1;

    @include responsive(tablet) {
      padding-top: 5px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("../../../../public/images/trianglesBlack.png");
      background-position: -40px 61.5%;
      opacity: 0.03;
      z-index: -1;

      @include responsive(phone) {
        display: none;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      background: linear-gradient(0deg, #fff 25%, hsla(0, 0%, 100%, 0));

      @include responsive(phone) {
        display: none;
      }
    }

    & > .title {
      background: var(--gradient-bg);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      z-index: 1;

      @include responsive(desktop) {
        font-size: 24px;
        line-height: 26px;
      }
    }
  }
}
// services

// news
.news {
  background-color: var(--white);
  color: var(--primary_main);
  padding: 20px 0px 32px;

  &.mt {
    padding-top: 60px;

    @include responsive(tablet) {
      padding-top: 20px;
    }
    @include responsive(phone) {
      padding-top: 1px;
    }
  }

  @include responsive(tablet) {
    padding: 20px 12px 32px;
  }
}
// news
